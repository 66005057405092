import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { Redirect } from 'react-router-dom'

import Header from './../components/Header';
import Search from './../components/Search';

import Hero from '../../../assets/images/hero-background.jpg';


const Travel = ({ theme_color, changeTheme, user }) => {
  const [loading, setLoading] = useState(false);

  const pushFilter = useCallback((title, value, multiselect) => {

  },[])

  if(!localStorage.getItem('token')) return <Redirect to="/login"/>
  return (
    <div className="travel-page">
      <div className={`hero ${ theme_color }`} style={ theme_color === 'theme-dark' ? { backgroundImage: `url(${ Hero })` } : null}>
        <div className="container">

          <Header theme_color={ theme_color } changeTheme={ changeTheme } user={ user }/>

          <div className={`top-content ${ theme_color }`}>
            <h1>Travel</h1>
            <div className="search-form">
              {/* <Search 
                placeholder={ 'Search travel' }
                pushFilterProp= { pushFilter }
              /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="coming-soon">
          <h5>Coming Soon</h5>
        </div>
      </div>
      
    </div>
  );
}

export default Travel