import React, { useEffect, useState } from 'react'

const ToggleButton = ({ changeTheme, theme_color }) => {

  const [toggle, setToggle] = useState(theme_color === 'theme-white' || localStorage.getItem('theme-color') === 'theme-white' ? true : false);

  const triggerToggle = () => {
    setToggle(!toggle)
    changeTheme(toggle ? 'theme-dark' : 'theme-white');
  }

  return(
    <div className="toggle-button">
      <div onClick={triggerToggle} className={`wrg-toggle ${toggle ? 'wrg-toggle--checked' : ''}`}>
        <div className="wrg-toggle-container">
          <div className="wrg-toggle-check">
            <span>🌜</span>
          </div>
          <div className="wrg-toggle-uncheck">
            <span>🌞</span>
          </div>
        </div>
        <div className="wrg-toggle-circle"></div>
        <input className="wrg-toggle-input" type="checkbox" aria-label="Toggle Button" />
      </div>
    </div>
  )
}

export default ToggleButton;