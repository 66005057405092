import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { GoogleLogin, useGoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../utils/refreshToken';

import Header from './../components/Header';
import Search from './../components/Search';
import Select from './../components/Select';
import SelectReactSimple from '../components/SelectReactSimple';
import HandleQuote from './../modals/HandleQuote';
import ShareQuote from './../modals/ShareQuote';
import HandleCategory from './../modals/HandleCategory';
import ThreeDotsWave from '../components/ThreeDotsLoading';
// import GenerateImgQuote from '../modals/GenerateImgQuote';
// import QuotesList from '../utils/quotes.json';

import svgs from '../utils/svgs';

import Hero from '../../../assets/images/hero-background.jpg';

const clientId = '595961559728-cvi6op2htv7bs311ai91psn6497hneov.apps.googleusercontent.com';
const cookiePolicy = 'single_host_origin'
const accessType = 'offline'
const isSignedIn = true
const scopes = 'profile email https://www.googleapis.com/auth/photoslibrary.readonly https://www.googleapis.com/auth/photoslibrary.appendonly https://www.googleapis.com/auth/photoslibrary.sharing https://www.googleapis.com/auth/photoslibrary'

const Quotes = ({ theme_color, changeTheme, user }) => {
  const [quotesState, setQuotes] = useState([])
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [tags, setTags] = useState([]);
  const [archives, setArchives] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);
  const [countQuotes, setCountQuotes] = useState(null);
  const [resetSearch, setResetResearch] = useState(false);
  const [scrollTopButton, setScrollTopButton] = useState(false);
  const [categoryModalStatus, setCategoryModalStatus] = useState(false);
  const [categoryToUpdate, setCategoryToUpdate] = useState('');

  const { signIn, loaded } = useGoogleLogin({
    onSuccess,
    clientId,
    cookiePolicy,
    isSignedIn,
    onFailure,
    accessType,
    scope: scopes
  })

  const routeParams = useLocation().state

  const quotesStateRef = useRef();
  quotesStateRef.current = quotesState;

  const filtersRef = useRef();
  filtersRef.current = filters

  const loadingMoreRef = useRef();
  loadingMoreRef.current = loadingMore;

  const countQuotesRef = useRef();
  countQuotesRef.current = countQuotes

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[quotesState])

  useEffect(() => {
    getCountQuotes();
  }, [])

  useEffect(() => {
    // importQuotesScript(QuotesList.all_quotes);

    if(!routeParams){
      getQuotes(null)
      getQuotesPrefillFilters();
    }
  },[setQuotes])

  // const importQuotesScript = async (quotes) => {
  //   for await(let quote of quotes){
  //     await axios.post('/api/v1/quotes.json',{
  //       quote_title: quote.quote_title,
  //       quote_description: quote.quote_description || "",
  //       quote_page: quote.page_name || "",
  //       quote_tags: quote.tags && quote.tags.length ? quote.tags : null,
  //       quote_authors: quote.author_name ? [quote.author_name] : null,
  //       quote_categories: quote.category_name ? [quote.category_name] : null,
  //       quote_sources: quote.source_name ? [quote.source_name] : null,
  //       quote_post_date: quote.quote_post_date ? quote.quote_post_date : null
  //     }).catch(err=> console.log('Err: - ', err))
  //   }
  // }

  async function handleScroll() {
    document.documentElement.scrollTop > 10 ? setScrollTopButton(true) : setScrollTopButton(false)
    if (window.innerHeight + document.documentElement.scrollTop !== document.querySelector('body>div').offsetHeight) return;
    if(!loadingMoreRef.current) return await loadMore();
  }

  async function loadMore(){
    setLoadingMore(true)
    let new_arr = [...filters]
    let filters_url = '?offset=' + quotesState.length;

    if(new_arr.find(na => na.title === 'Tags')){
      filters_url += `&filter_by_tags=${ encodeURIComponent(new_arr.find(na => na.title === 'Tags').values.join()) }`
    }
    
    if(new_arr.find(na => na.title === 'Search')){
      filters_url += `&search=${ encodeURIComponent(new_arr.find(na => na.title === 'Search').values[0].toLowerCase()) }`
    }

    if(new_arr.find(na => na.title === 'Category')){
      filters_url += `&filter_by_category=${ new_arr.find(na => na.title === 'Category').values.join() }`
    }

    if(new_arr.find(na => na.title === 'Archive')){
      filters_url += `&filter_by_archive=${ new_arr.find(na => na.title === 'Archive').values.join() }`
    }

    await axios.get('/api/v1/quotes.json' + filters_url)
      .then(res=> {
        var more_quotes = [];

        res.data.data.forEach(quote => {
          if(!quotesState.find(current => current.id === quote.id)){
            more_quotes.push(quote);
          }
        })

        if(!quotesState.length){
          setQuotes(more_quotes);
        } else {
          setQuotes(currentQuotes => [...currentQuotes,...more_quotes])
        }
      })
    setLoadingMore(false)
  }

  const addQuote = useCallback((quote) => {
    let newArr = [...quotesStateRef.current]

    newArr.unshift(quote.data)
    setQuotes(newArr);
    setCountQuotes(countQuotesRef.current+1)
    refreshFilters()
  },[])

  const editQuote = useCallback((quote) => {
    let newArr = [...quotesStateRef.current]
    let indexQuote = newArr.findIndex(d => parseInt(d.id) == quote.id)

    newArr[indexQuote] = quote;
    setQuotes(newArr)
    refreshFilters()
  }, [])

  const removeQuote = useCallback((id) => {
    let newArr = [...quotesStateRef.current]

    newArr = newArr.filter(d => parseInt(d.id) !== id)
    setQuotes(newArr)
    setCountQuotes(countQuotesRef.current-1)
    refreshFilters()
  }, []);

  const generateImageQuote = (id, generated_at, generated_url, updated_at) => {
    setQuotes(
      quotesStateRef.current.map(item => 
          parseInt(item.id) == parseInt(id)
          ? {...item, attributes : { ...item.attributes, generated_at: generated_at, generated_url: generated_url, updated_at: updated_at}} 
          : item 
    ))
  }

  const pushFilter = useCallback((title, value, multiselect, submit) => {
    let new_arr = [...filtersRef.current];

    //take index of title filter (title represent the category of entire filter)
    let indexFilter = new_arr.findIndex(na => na.title === title);

    if(indexFilter < 0){
      if(value){
        new_arr.push({ title: title, values: [value]})
      }
    } else {
      let indexValue = new_arr[indexFilter].values.findIndex(v => v === value);

      if(indexValue < 0){
        if(!multiselect){
          if(value){
            new_arr[indexFilter].values = [value]
          } else {
            new_arr = new_arr.filter((_,index) => index !== indexFilter);
          }
        } else {
          new_arr[indexFilter].values.push(value)
        }
      }
    }

    let filters_url = '?';

    if(new_arr.find(na => na.title === 'Tags')){
      filters_url += `&filter_by_tags=${ encodeURIComponent(new_arr.find(na => na.title === 'Tags').values.join()) }`
    }
    
    if(new_arr.find(na => na.title === 'Search')){
      filters_url += `&search=${ encodeURIComponent(new_arr.find(na => na.title === 'Search').values[0].toLowerCase()) }`
    }

    if(new_arr.find(na => na.title === 'Category')){
      filters_url += `&filter_by_category=${ new_arr.find(na => na.title === 'Category').values.join() }`
    }

    if(new_arr.find(na => na.title === 'Archive')){
      filters_url += `&filter_by_archive=${ new_arr.find(na => na.title === 'Archive').values.join() }`
    }

    if(submit){
      getQuotes(filters_url);
    }
    
    setFilters(new_arr)
  },[])

  const clearFilters = () => {
    setFilters([])
    setResetResearch(true)
    setTimeout(() => {
      setResetResearch(false)
    },100)
    if(!filters.find(f => f.title === 'Search')){
      getQuotes(null);
    }
  }

  const getCountQuotes = async () => {
    await axios.get('/api/v1/count-quotes')
      .then(res => {
        if(res.data.success){
          setCountQuotes(res.data.count)
        }
      })
  }

  const getQuotes = async (filters) => {
    setLoading(true)
    await axios.get(`/api/v1/quotes.json${ filters }`)
      .then(res => {
        setQuotes(res.data.data)
      })

    setLoading(false)
  }

  const getQuotesPrefillFilters = () => {
    axios.get('/api/v1/prefill-filter-quotes.json')
      .then(res=>{
        if(res.data.archives){
          let archivesOptions = [];

          var start = moment(res.data.archives.first_month, 'YYYY-MM-DD')
          var end = moment(res.data.archives.last_month, 'YYYY-MM-DD')

          while (start < end) {
            archivesOptions.push({ value: start.format('MMMM YYYY'), label: start.format('MMMM YYYY')})
            start.add(1, 'month')
          }

          setArchives(archivesOptions)
        }

        if(res.data.authors){
          let authorsOptions = [];

          res.data.authors.forEach(a => {
            authorsOptions.push({ value: a.id, label: a.title })
          })

          setAuthors(authorsOptions);
        }

        if(res.data.tags){
          let tagsOptions = [];

          res.data.tags.forEach(a => {
            tagsOptions.push({ value: a.id, label: a.title})
          })

          setTags(tagsOptions);
        }

        if(res.data.categories){
          let categoriesOptions = [];

          res.data.categories.forEach(a => {
            categoriesOptions.push({ value: a.id, label: a.title})
          })

          setCategories(categoriesOptions);
        }

        if(res.data.sources){
          let sourcesOptions = [];

          res.data.sources.forEach(a => {
            sourcesOptions.push({ value: a.id, label: a.title })
          })

          setSources(sourcesOptions);
        }
      })
  }

  const refreshFilters = () => {
    setCategories([]);
    setTags([]);
    setArchives([]);
    setFilters([]);
    setSources([])

    getQuotesPrefillFilters();
  }

  const singleFilter = async (type, value) => {
    await refreshFilters();
    pushFilter(type, value, false, true);
  }


  async function onSuccess (res) {
    // Refresh Token
    refreshTokenSetup(res);

    localStorage.setItem('token', res.tokenId)
    localStorage.setItem('access_token', res.accessToken)
  }

  async function onFailure (res) {
    console.log('Failed Login', res)
  }

  const removeOption = async (id) => {
    const { data } = await axios.delete(`/api/v1/quote/category/${ id }`)

    if(data?.success){
      setCategories(categories.filter(category => category.value !== id))
    }
  }

  const submitEditCategory = async (newCategoryName) => {
    const { data } = await axios.put(`/api/v1/quote/category/${ categoryToUpdate }`, { 
      category_title: newCategoryName
    })

    if(data?.success) return setCategories(categories.map(category => category.value === categoryToUpdate ? { ...category, label: newCategoryName, name: newCategoryName } : { ...category }))
  }

  if(!localStorage.getItem('token')) return <Redirect to="/login"/>

  return (
    <div className="quotes-page">
      <div className={`hero ${ theme_color }`} style={ theme_color === 'theme-dark' ? { backgroundImage: `url(${ Hero })` } : null}>
        <div className="container">

          <Header theme_color={ theme_color } changeTheme={ changeTheme } user={ user }/>

          <div className={`top-content ${ theme_color }`}>
            <h1>Quotes</h1>
            <div className="search-form">
              <Search 
                placeholder={ 'Search quotes' }
                pushFilterProp= { pushFilter }
                prefillValueProp = { routeParams && routeParams.search_param ? routeParams.search_param : null}
                reset={ resetSearch }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={`actions-container ${ theme_color }`}>

          <div className="left-column">
            {
              quotesState.length || filters ?
                <Select
                  title="Archive"
                  values={ archives.map(a => { a.name = a.label; return a }) }
                  pushFilterProp= { pushFilter }
                  theme_color={ theme_color }
                /> : null
            }
            
            {
              quotesState.length || filters ?
                <SelectReactSimple
                  multiselect = { true }
                  placeholder = "Tags"
                  addNewValues = { false }
                  options = { tags.map(t => { t.name = t.label; return t }) }
                  pushFilterProp = { pushFilter }
                  theme_color = { theme_color }
                />
                : null
            }

            {
              quotesState.length || filters ?
                <SelectReactSimple
                  multiselect = { false }
                  placeholder = "Category"
                  addNewValues = { false }
                  options = { categories.map(t => { t.name = t.label; return t }) }
                  pushFilterProp = { pushFilter }
                  theme_color = { theme_color }
                  removeOptionProp = { removeOption }
                  handleModalCategoryProp = { () => setCategoryModalStatus(!categoryModalStatus) }
                  handleCategoryNameProp = { (category_name) => setCategoryToUpdate(category_name)}
                />
                : null
            }
          </div>

          <div className="right-column">
            <HandleQuote
              button_title = 'Add New Quote'
              button_classes = {`button add_quote ${ theme_color }`}
              add_quote = { true }
              addQuoteProp = { addQuote }
              generateImageQuoteProp = { generateImageQuote }
              prefillFiltersProp = {{
                authors: authors,
                tags: tags,
                categories: categories,
                sources: sources
              }}
              theme_color={ theme_color }
            />
          </div>
         
        </div>

        {
          filters.length ?
            <div className={`actions-filters ${ theme_color }`}>
              <button className={`button transparent ${ theme_color }`} onClick={() => clearFilters()}>Clear all filters</button>

              {
                filters.map((filter,index) => (
                  <div className="filter-element" key={ index }>
                    <span className="title">{ filter.title }:</span>
                    {
                      filter.values.map((v,index) => (
                        <button className="border" key={ index }>{ v }</button>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          : null
        }

        <div className={`quotes-list ${ theme_color }`}>
          {
            countQuotes ? 
              <div className="total"><p>Total:<span>{ countQuotes }</span></p></div>
            : null
          }
          {
            loading ? 
              <div className="loading">
                <ThreeDotsWave color={ theme_color === 'theme-white' ? 'black' : 'white' }/>
              </div>
            :
              quotesState && quotesState.length ?
                quotesState.map(quote => (
                  <div key={ quote.id } className="quote">

                    <div className="quote-head">
                      <span className="quote-id-date">#{quote.id} | { moment(quote.attributes.created_at).format('DD MMM YYYY') }</span>
                      <ShareQuote
                        button_title = 'Share Quote'
                        button_classes = 'share-quote'
                        theme_color = { theme_color }
                        data = { quote }
                      />
                      {/* <GenerateImgQuote
                        button_title = 'Generate Image'
                        button_classes = 'generate-image'
                        theme_color = { theme_color }
                        data = { quote }
                        generateImageQuoteProp = { generateImageQuote }
                      /> */}
                    </div>
                    <div className="quote-content">
                      <HandleQuote
                        button_title = { quote.attributes.title }
                        button_classes = 'quote_edit quote_edit_element' 
                        edit_quote = { true }
                        data = { quote.attributes }
                        editQuoteProp = { editQuote }
                        removeQuoteProp = { removeQuote }
                        generateImageQuoteProp = { generateImageQuote }
                        prefillFiltersProp = {{
                          authors: authors,
                          tags: tags,
                          categories: categories,
                          sources: sources
                        }}
                        selectedFiltersProp = {{
                          authors: quote.attributes.authors ? quote.attributes.authors.map(author => ({ value: author.id, label: author.title })) : [],
                          tags: quote.attributes.tags ? quote.attributes.tags.map(tag => ({ value: tag.id, label: tag.title })) : [],
                          categories: quote.attributes.categories ? quote.attributes.categories.map(category => ({ value: category.id, label: category.title })) : [],
                          sources: quote.attributes.sources ? quote.attributes.sources.map(source => ({ value: source.id, label: source.title })) : []
                        }}
                        theme_color = { theme_color }
                        user = { user }
                      />
                      <button href="" className="quote_edit quote_edit_element"><h3 className="title">{  }</h3></button>
                      <h4 className="content" dangerouslySetInnerHTML={{ __html: quote.attributes.description.replace(/<\/?span[^>]*>/g,"") }}></h4>
                    </div>
                    <div className="quote-info">
                      {
                        quote.attributes.sources && quote.attributes.sources.length ?
                          <div className="filter-element">
                            <span className="title">Title:</span>
                            { 
                              quote.attributes.sources.map((source,index) => (
                                <button key={ source.id } className="border no-cursor">{ source.title }</button> 
                              ))
                            }
                          </div>
                        : null
                      }
                      {
                        quote.attributes.page ?
                          <div className="filter-element">
                            <span className="title">Page:</span>
                            <button className="border no-cursor">{ quote.attributes.page }</button> 
                          </div>
                        : null
                      }
                      {
                        quote.attributes.authors && quote.attributes.authors.length ?
                          <div className="filter-element">
                            <span className="title">Authors:</span>
                            { 
                              quote.attributes.authors.map((author,index) => (
                                <button className="border no-cursor" key={ author.id }>{ author.title }</button> 
                              ))
                            }
                          </div>
                        : null
                      }
                      {
                        quote.attributes.tags && quote.attributes.tags.length ?
                          <div className="filter-element">
                            <span className="title">Tags:</span>
                            { 
                              quote.attributes.tags.map((tag,index) => (
                                <button className="border" key={ tag.id } onClick={() => singleFilter('Tags', tag.title)}>{ tag.title }</button> 
                              ))
                            }
                          </div>
                        : null
                      }
                      {
                        quote.attributes.categories && quote.attributes.categories.length ?
                          <div className="filter-element">
                            <span className="title">Category:</span>
                            { 
                              quote.attributes.categories.map((category,index) => (
                                <button key={ category.id } className="border" onClick={() => singleFilter('Category', category.title)}>{ category.title }</button> 
                              ))
                            }
                          </div>
                        : null
                      }
                    </div>
                  </div>
                ))
              : <center>Unfortunately no quotes were found!</center>
          }

          {
            scrollTopButton ? 
              <button className={`scroll-up ${ theme_color }`} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                { svgs.up_arrow }
              </button>
            : null
          }
        </div>

        <HandleCategory 
          modalStatus={ categoryModalStatus }
          handleStatus={ () => setCategoryModalStatus(!categoryModalStatus) }
          categoryName={ categories.length && categoryToUpdate ? categories.find(category => category.value === categoryToUpdate).label : '' }
          submitEditCategoryProp={ submitEditCategory }
        />

        {
          loadingMore && !loading ? 
            <div className="loading">
              <ThreeDotsWave/>
            </div>
          : null
        }
      </div>
    </div>
  );
}

export default Quotes