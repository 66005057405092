import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Modal from 'react-modal';
import Toggle from 'react-toggle';

import Logo from '../../../assets/images/logo.png';
import LogoWhite from '../../../assets/images/logo-white.png';
import { NavLink } from 'react-router-dom'
import ToggleButton from './ToggleButton';
import Select from './Select';
import Search from './Search';
import axios from 'axios';
import svgs from '../utils/svgs';
import ThreeDotsWave from './ThreeDotsLoading';
import { googleLogout } from '@react-oauth/google';

const customStyles = {
  content: {
    top: '20%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 3
  }
};

const Header = React.memo(({ home, theme_color, changeTheme, user }) => {
  const history = useHistory();

  const [filter, setFilter] = useState('All');
  const [sideDrawer, toggleSideDrawer] = useState(false);
  const [filters, setFilters] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState(false);

  const filtersRef = useRef();
  filtersRef.current = filters;

  const searchInputRef = useRef();
  searchInputRef.current = searchInput

  const goToResult = (type_search, title) => {
    var route = ''
    var route_params = {
      search_param: title
    }

    if(type_search === 0){
      route = 'quotes'
    } else if (type_search === 1){
      route = 'dreams'
    } else if (type_search === 5 || type_search === 6){
      route = 'libraries'
      route_params.library_type = type_search === 5 ? 0 : 1
    }

    if(searchInput.length){
      history.push(route, { ...route_params });
    }
  }

  const pushFilter = useCallback((title, value, multiselect) => {
    let new_arr = [...filtersRef.current];

    if(title === 'Search'){
      setSearchInput(value)
    } else if(title === 'Filter By'){
      setFilter(value);
    }

    let indexFilter = new_arr.findIndex(na => na.title === title);

    if(indexFilter < 0){
      if(value){
        new_arr.push({ title: title, values: [value]})
      }
    } else {
      let indexValue = new_arr[indexFilter].values.findIndex(v => v === value);

      if(indexValue < 0){
        if(!multiselect){
          if(value){
            new_arr[indexFilter].values = [value]
          } else {
            new_arr = new_arr.filter((_,index) => index !== indexFilter);
          }
        } else {
          new_arr[indexFilter].values.push(value)
        }
      }
    }

    let filters_url = '?';
    
    if(new_arr.find(na => na.title === 'Search')){
      filters_url += `&search=${ new_arr.find(na => na.title === 'Search').values[0].toLowerCase() }`
    }

    if(new_arr.find(na => na.title === 'Filter By')){
      filters_url += `&filter_by=${ new_arr.find(na => na.title === 'Filter By').values[0].toLowerCase() }`
    }

    if((value.length > 1 && title === 'Search') || (searchInputRef.current.length > 1 && title !== 'Search')){
      getSearchResults(filters_url, title === 'Search' ? value : searchInputRef.current)
    } else {
      setSearchResults([])
    }

    setFilters(new_arr)
  },[])

  const closeSideDrawer = (event) => {
    event.stopPropagation()
    toggleSideDrawer(!sideDrawer);
  }

  const getSearchResults = (filters, searchInput) => {
    axios.get('/api/v1/search' + filters)
      .then(res=> {
        if(res.data && res.data.results){
          var results = [];
          if(res.data.results.length){
            for (let result of res.data.results){
              var in_title = true;
              // we check firstly in title and than in description
              let indexSearchInput = result.title.toLowerCase().indexOf(searchInput.toLowerCase())
              if(indexSearchInput < 0){

                indexSearchInput = result.description.toLowerCase().indexOf(searchInput.toLowerCase())
                in_title = false;
              }

              if((in_title && result.title.length > 23) || (!in_title && result.description.length > 23)){
                if(indexSearchInput >= 10){
                  results.push({ 
                    id: result.id, 
                    type_search: result.type_search, 
                    title_result: '...' 
                      + 
                      (
                        in_title ? 
                          result.title.substring(indexSearchInput - 10, indexSearchInput + searchInput.length + 5) 
                        + 
                          (result.title[indexSearchInput + searchInput.length + 6] ? '...' : 'muie')
                      : 
                          removeTags(result.description.substring(indexSearchInput - 10, indexSearchInput + searchInput.length + 5)) 
                        + 
                          (removeTags(result.description[indexSearchInput + searchInput.length + 6]) ? '...' : '')
                      ) 
                      ,
                    title_complete: result.title
                  })
                } else {
                  results.push({ 
                    id: result.id, 
                    type_search: result.type_search, 
                    title_result: (in_title ? result.title.substring(0, 22) : removeTags(result.description.substring(0, 22))) + '...',
                    title_complete: result.title
                  })
                }
              } else {
                results.push({ 
                  id: result.id, 
                  type_search: result.type_search, 
                  title_result: in_title ? result.title : removeTags(result.description),
                  title_complete: result.title 
                })
              }
            }
            setSearchResults(results)
          } else {
            setSearchResults([{
              id: null,
              type_search: null,
              title_result: 'No results found!'
            }])
          }
        } else {
          setSearchResults([{
            id: null,
            type_search: null,
            title_result: 'No results found!'
          }])
        }
      })
  }

  //Remove HTML Tags
  function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();

    return str.replace( /(<([^>]+)>)/ig, '');
  }

  async function openModal() {
    setIsOpen(true);
    setLoadingUsers(true);
    await getUsers();

    setLoadingUsers(false)
  }

  function closeModal() {
    setIsOpen(false);
  }

  const getUsers = async () => {
    var { data } = await axios.get('/api/v1/list-users')

    if(data && data.users){
      const decoded = jwt_decode(localStorage.getItem('token'));

      setUsers(data.users.filter(u => u.email !== decoded.email))
    } 
  }

  const handleRole = (id) => {
    setUsers(users.map(user => 
      user.id == id 
        ?
          { ...user, role: user.role === 1 ? 0 : 1 } 
        :
          user
    ))
    axios.put('/api/v1/user/update-role', {
      email: users.find(u => u.id === id).email,
      role: users.find(u => u.id === id).role === 1 ? 0 : 1
    })
  }

  const SignOut = () => {
    googleLogout();
    localStorage.removeItem('token');
    localStorage.removeItem('access_token');
    location.reload();
  }

  return (
    <section className={`header ${ theme_color }`} style={ home ? { paddingBottom: 10 } : null}>
      <div className="top-bar">
        <div className="left-top">
          {
            !home ? 
              <button className={`toggle-button-menu ${ theme_color }`} onClick={() => toggleSideDrawer(!sideDrawer)}>
                <div className="toggle-button__line" />
                <div className="toggle-button__line" />
                <div className="toggle-button__line" />
              </button>
            : null
          }
        </div>

        <div className="right-top">
          <button className="sign-out" onClick={SignOut}>Sign Out</button>
          {
            user && user.role === 0 ?
              <button className="settings" onClick={() => openModal()}>
                { svgs.settings }
                Settings
              </button>
            : 
              null
          }
          <ToggleButton theme_color={ theme_color} changeTheme={ changeTheme }/>
        </div>
      </div>
      <div className="logo-column">
        <a href="/"><img src={ theme_color === 'theme-dark' ? LogoWhite : Logo } alt="logo"/></a>
      </div>
      <div className="right-column">
        {
          home ?
            <div className="search-form">
              <Search 
                placeholder={ 'Search something' }
                home = { true }
                pushFilterProp= { pushFilter }
              />
            </div>
          :
            <ul className={`navmenu small ${ theme_color }`}>
              <li><NavLink to="/dreams" activeClassName="active">Dream List</NavLink></li>
              <li><NavLink to="/quotes" activeClassName="active">Quotes</NavLink></li>
              <li><NavLink to="/travel" activeClassName="active">Travel</NavLink></li>
              <li><NavLink to="/libraries" activeClassName="active">Library</NavLink></li>
              <li><NavLink to="/talks" activeClassName="active">Talks</NavLink></li>
              <li><NavLink to="/principles" activeClassName="active">Principles</NavLink></li>
              <li><NavLink to="/private-island" activeClassName="active">Private Island</NavLink></li>
            </ul>
        }
      </div>
        {
          home && <div className="filter-column">
                    <div className="filter-container">
                      <p>Filter by:</p>
                      <Select
                        title="Filter By"
                        values={[
                          { name: "All" },
                          { name: "Dream List"},
                          { name: "Quotes" },
                          { name: "Library" }
                        ]}
                        pushFilterProp= { pushFilter }
                        theme_color={ theme_color }
                        home={ true }
                        selected_value={ filter }
                      />
                    </div>
                    
                  </div>
        }
        {
          home && searchResults.length ? 
            <div className="search-results">
              <h6>Search results:</h6>
                <ul className="results-list">
                  {
                    searchResults.map((item,index) => {
                      var indexSearchInput = item.title_result.toLowerCase().indexOf(searchInput.toLowerCase());
                      return  <li key={ index }>
                                {
                                  item.id ?
                                    <button onClick={ item.id ? () => goToResult(item.type_search, item.title_complete) : null}>

                                            { item.title_result.substring(0,indexSearchInput) }
                                            <span>{ item.title_result.substring(indexSearchInput, indexSearchInput + searchInput.length) }</span>
                                            { item.title_result.substring(item.title_result.toLowerCase().indexOf(searchInput.toLowerCase()) + searchInput.length, item.title_result.length) }
                                    </button>
                                  : 
                                    <p>{ item.title_result }</p>
                                }
                              </li>
                    })
                  }
                </ul>
            </div>
          : null
        }

      <nav className={`side-drawer ${ theme_color } ${ sideDrawer ? 'open' : '' }`}>
        <div className="side-drawer-overlay" onClick={closeSideDrawer}></div>
        <div className="side-drawer-content">
          <button className="side-drawer-close" onClick={() => toggleSideDrawer(!sideDrawer)}>x</button>
          <ul className={`navmenu small ${ theme_color }`}>
            <li><NavLink to="/dreams" activeClassName="active">Dream List</NavLink></li>
            <li><NavLink to="/quotes" activeClassName="active">Quotes</NavLink></li>
            <li><NavLink to="/travel" activeClassName="active">Travel</NavLink></li>
            <li><NavLink to="/libraries" activeClassName="active">Library</NavLink></li>
            <li><NavLink to="/talks" activeClassName="active">Talks</NavLink></li>
            <li><NavLink to="/principles" activeClassName="active">Principles</NavLink></li>
            <li><NavLink to="/private-island" activeClassName="active">Private Island</NavLink></li>
          </ul>
        </div>
      </nav>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        htmlOpenClassName="list-users"
      >
        <h4>Admins</h4>
        <div className="content">
          {
            loadingUsers ?
              <div className="loading">
                <ThreeDotsWave color="black"/>
              </div>
            : 
              users.length ?
                <ul className="users">
                  {
                    users.map((user, index) => {
                      return  <li key={ index }>
                                <p>{ user.email }</p>
                                <Toggle
                                  defaultChecked={user.role === 0 ? true : false}
                                  aria-label='No label tag'
                                  onChange={() => handleRole(user.id)} 
                                />
                              </li>
                    })
                  }
                </ul>
              : <center>No users!</center>
          }
        </div>
      </Modal>
    </section>
  )
})

export default Header