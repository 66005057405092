import React, { useEffect, useState, useCallback } from 'react'
import { Route, Switch } from 'react-router-dom'
import jwt_decode from 'jwt-decode';
import axios from 'axios'

import Home from './pages/Home';
import Dreams from './pages/Dreams';
import Quotes from './pages/Quotes';
import Libraries from './pages/Libraries';
import Travel from './pages/Travel';
import Talks from './pages/Talks';
import Principles from './pages/Principles';
import PrivateIsland from './pages/PrivateIsland';
import Login from './pages/Login';

const App = () => {
  const [colorTheme, setColorTheme ] = useState('theme-dark');
  const [user, setUser] = useState(null)

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('theme-color');
    const userToken = localStorage.getItem('token')

    if(userToken){
      let userTokenDocoded = jwt_decode(userToken);

      axios.get(`/api/v1/get-user?email=${ userTokenDocoded.email }`)
        .then(res=> {
          if(res.data && res.data.user){
            setUser(res.data.user)
          }
        })
    }

    if(currentThemeColor){
      setColorTheme(currentThemeColor);
    }
  },[])

  const handleTheme = (theme) => {
    localStorage.setItem('theme-color', theme)
    setColorTheme(theme);
  }

  return (
    <div className={`App ${ colorTheme }`}>
      <Switch>
        <Route exact path="/" render={() => <Home theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/>}/>
        <Route exact path="/dreams" render={ () => <Dreams theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/> }/>
        <Route exact path="/login" render={() => <Login theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/>}/>
        <Route exact path="/quotes" render={ () => <Quotes theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/> }/>
        <Route exact path="/libraries" render={ () => <Libraries theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/> }/>
        <Route exact path="/travel" render={ () => <Travel theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/> }/>
        <Route exact path="/talks" render={ () => <Talks theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/> }/>
        <Route exact path="/principles" render={ () => <Principles theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/> }/>
        <Route exact path="/private-island" render={ () => <PrivateIsland theme_color={ colorTheme } changeTheme={ handleTheme } user={ user }/> }/>
      </Switch>
    </div>
  )
}

export default App;