import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';

import Header from '../components/Header';
import ProfilePic from '../../../assets/images/home-profile.jpg'
import HomeBackground from '../../../assets/images/home-background.jpg';

const Home = ({ theme_color, changeTheme, user }) => {

  if(!localStorage.getItem('token')) return <Redirect to="/login"/>
  return (
    <div className={`home-page ${ theme_color }`} style={ theme_color === 'theme-dark' ? { backgroundImage: `url(${ HomeBackground })` } : null}>
      <div className="container large">
        <Header home={ true } theme_color={ theme_color } changeTheme={ changeTheme } user={ user }/>
      </div>
      <div className="container large second">
        <div className="content">

          <div className="left-column">
              <ul className={`navmenu ${ theme_color }`}>
                <li><a href="/dreams">Dream List</a></li>
                <li><a href="/quotes">Quotes</a></li>
                <li><a href="/travel">Travel</a></li>
                <li><a href="/libraries">Library</a></li>
                <li><a href="/talks">Talks</a></li>
                <li><a href="/principles">Principles</a></li>
                <li><a href="/private-island">Private Island</a></li>
              </ul>
          </div>

          <div className="right-column">
            <div className="image">
              <img src={ ProfilePic } alt=""/>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Home