// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from '../react/App';
import { GoogleOAuthProvider } from '@react-oauth/google';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <GoogleOAuthProvider clientId="230544493848-rdt34f9bi9c623t9j8p3eim4pp60k1p9.apps.googleusercontent.com">
    <Router>
      <Route path="/" component={ App }/>
    </Router>
    </GoogleOAuthProvider>,
    document.body.appendChild(document.createElement('div')),
  )
})
