import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import axios from 'axios';
// import ThumbnailDefault from '../../../assets/images/video-thumbnail.jpg'
import VideoThumbnail from 'react-video-thumbnail';
import svgs from '../utils/svgs';
import ThreeDotsWave from './../components/ThreeDotsLoading';
import { Editor } from '@tinymce/tinymce-react'; 

axios.defaults.withCredentials = process.env.NODE_ENV !== 'development';

const DreamModal = ({ selectedDreamDetails, setSelectedDreamDetails, editDreamProp }) => {

  const [description, setDescription] = useState(selectedDreamDetails.attributes.description);
  const [isOpenThumbnail, setIsOpenThumbnail] = useState(true);
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(true);
  const [editDescription, setEditDescription] = useState(false);

  const handleClick = (e) => {
    if(e.target.classList.contains('dream-modal')){
      setSelectedDreamDetails(null)
    }
  }

  const submitDescription = () => {
    if(selectedDreamDetails.attributes.description !== description){
      axios.post('/api/v1/edit-dream-action.json',{
        dream_id: selectedDreamDetails.attributes.id,
        dream_description: description
      })
        .then(res=>{
          if(res.data){
            editDreamProp(res.data.data)
          }
        })
    }
  }

  // useEffect(() => {
  //   const timeOutId = setTimeout(() => submitDescription(description), 800);
  //   return () => clearTimeout(timeOutId);
  // }, [description]);

  return (
    <motion.div className="dream-modal" onClick={handleClick} initial={{ opacity: 0 }} animate={{ opacity: 1}}>
      <motion.div className="content" initial={{ y: "-100vh" }} animate={{ y: 0 }}>
        {
          selectedDreamDetails.attributes.media_after_type.indexOf('image') > -1 ?
            <motion.img src={ selectedDreamDetails.attributes.media_after_url_large } alt="enlarged pic" />
          : 
            isOpenThumbnail ? 
              <div className="thumbnail" onClick={() => setIsOpenThumbnail(false)}>
                <div style={isLoadingThumbnail ? { display: 'none' } : { width: '100%', height: '100%' }}>
                  <VideoThumbnail
                    videoUrl={selectedDreamDetails.attributes.media_after_url}
                    thumbnailHandler={() => setIsLoadingThumbnail(false)}
                  />
                </div>
                { 
                  isLoadingThumbnail ? 
                    <div className="loading">
                      <ThreeDotsWave color={"black"}/> 
                    </div>
                  : 
                    <button className="thumbnail-play">
                      { svgs.play_video }
                    </button>
                }
              </div>
            :  
              <video width="100%" height="100%" controls autoPlay={true}>
                <source src={ selectedDreamDetails.attributes.media_after_url } type="video/mp4"/>
              </video>
        }

        <div className="dream-description">
          {
            editDescription ? 
              <Editor
                apiKey="83h704apqy5jaskvr6onqcib9mmgbg0s4wc6zlu89kmlnvdr"
                // initialValue={ }
                value={description}
                init={{
                  placeholder: "Dream description",
                  height: '100%',
                  menubar: false,
                  branding: false,
                  toolbar: false,
                  statusbar: false,
                  plugins: [
                    'quickbars',
                    'link',
                  ],
                  content_style: 'html { height: 100%; }' + 'body { height: 100%; display: flex; align-items: center; margin: 0; min-height: auto; }' + `p { font-weight: 600; color: #222; margin: 0; }` + `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { font-weight: 600; color: #222; }`,
                  quickbars_insert_toolbar: '',
                  quickbars_selection_toolbar: 'link',
                  contextmenu: '',
                  setup: function (editor) {
                    editor.on('keydown', function (e) {
                    });
                  }
                }}
                onEditorChange={value => setDescription(value)}
              />
            :
              <p className="display" dangerouslySetInnerHTML={{ __html: description || 'Description' }}></p>
          }
          <button className={`handle-description ${ editDescription ? 'save' : null}`} onClick={editDescription ? () => { setEditDescription(false); submitDescription() } : () => setEditDescription(true) }>{ editDescription ? 'SAVE' : 'EDIT' }</button>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default DreamModal