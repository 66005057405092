import React, { useCallback, useEffect, useState } from "react"
import Modal from 'react-modal';
import axios from "axios";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 3
  }
};

Modal.setAppElement(document.createElement('div'));
const csrf_token = document.getElementsByName('csrf-token')[0].content;

axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token;

const RecomendLibrary = React.memo(({ theme_color, button_title, button_classes, data, books_status }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState('chris@iamamultiplier.com');
  const [to, setTo] = useState('');
  const [title, setTitle] = useState(`I recommend this ${ books_status ? 'book' : 'documentary' }: ${ data.attributes.title }`);
  const [message, setMessage] = useState(`You can find it here: ${ data.attributes.link_url || '' }`);
  const [status, setStatus] = useState('');

  customStyles.content = {...customStyles.content, width: (window.innerWidth < 768 ? '90%' : '700px')}

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setTo('');
    setTitle('');
    setMessage('');
  }

  async function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function submit(){
    setLoading(true);
    if(from.length && to.length && title.length && message.length){
      let is_valid_from = await validateEmail(from);

      if(is_valid_from){
        let is_valid_to = await validateEmail(to);

        if(is_valid_to){
          await axios.post('/api/v1/share-library',{
            email_from: from,
            email_to: to,
            email_title: title,
            email_message: message,
            library_type: books_status ? 'books' : 'documentaries'
          })
            .then(res => {
              setStatus(`This ${ books_status ? 'book' : 'documentary'} has been sent!`);
              setTo('');
              setTitle('');
              setMessage('');
            })
            .catch(err => console.log('Error share library: ', err))
        } else {
          setStatus('To email is not valid!');
        }
      } else {
        setStatus('From email is not valid!');
      }
    } else {
      setStatus('All fields are required!');
    }

    setLoading(false);
  }

  return (
    <div>
      <button className={ button_classes } onClick={() => openModal()}>
        { button_title }
      </button>
      {
        modalIsOpen && 
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            htmlOpenClassName="recomend-library"
          >
            <h3>SEND EMAIL</h3>
            <div className="content">
              { status ? <p className="status">{ status }</p> : null }
              <input type="text" name="share_from" value={from} onChange={event => { setFrom(event.target.value); setStatus('') }} placeholder="From"/>
              <input type="text" name="share_to" value={to} onChange={event => { setTo(event.target.value); setStatus('') }} placeholder="To"/>
              <input type="text" name="share_title" value={title} onChange={event => { setTitle(event.target.value); setStatus('') }} placeholder="Library Title"/>
              <textarea type="text" name="share_message" value={message} onChange={event => { setMessage(event.target.value); setStatus('') }} placeholder="Library Content"/>
              <button className="button upload-btn" onClick={() => submit()} disabled={ loading } style={ loading ? { opacity: 0.7 } : null }>{ button_title }</button>
            </div>
          </Modal>
      }
    </div>
  );
})

export default RecomendLibrary