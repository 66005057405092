import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'

import { refreshTokenSetup } from '../utils/refreshToken';
import HomeBackground from '../../../assets/images/home-background.jpg';
import Logo from '../../../assets/images/logo.png';
import LogoWhite from '../../../assets/images/logo-white.png';
import ProfilePic from '../../../assets/images/home-profile.jpg'

const cookiePolicy = 'single_host_origin'
const accessType = 'offline'
const isSignedIn = true
const scopes = 'profile email https://www.googleapis.com/auth/photoslibrary.readonly https://www.googleapis.com/auth/photoslibrary.appendonly https://www.googleapis.com/auth/photoslibrary.sharing https://www.googleapis.com/auth/photoslibrary'; // Add the required scopes

const Login = ({ theme_color, changeTheme }) => {
  const history = useHistory();

  const login = useGoogleLogin({
    onSuccess: (onSuccess),
    onError: (error) => console.log('Login Failed:', error),
    cookiePolicy:cookiePolicy,
    isSignedIn: isSignedIn ,
    scope: scopes ,
    accessType: accessType ,
});

function base64UrlEncode(str) {
  let base64 = btoa(unescape(encodeURIComponent(str)));
  return base64.replace('+', '-').replace('/', '_').replace(/=+$/, '');
}

const header = {
  alg: "none", // No algorithm because we're not signing
  typ: "JWT"
};


async function fetchUserInformation(access_token) {
  try {
    const response = await axios.get(`/api/v1/google/userinfo?access_token=${access_token}`);
    return response.data;  // This contains user's information
  } catch (error) {
    console.error("Error fetching user information:", error);
    return null;
  }
}

  async function onSuccess (res) {  
    var scopes = res.scope
    if(scopes.indexOf('photoslibrary.appendonly') >= 0 && scopes.indexOf('photoslibrary.readonly') >= 0 && scopes.indexOf('photoslibrary.sharing') >= 0){
      if((localStorage.getItem('token') && localStorage.getItem('token') !== res.tokenId) || !localStorage.getItem('token')){
        if (res.access_token) {
          const userInformation = await fetchUserInformation(res.access_token);
          const encodedHeader = base64UrlEncode(JSON.stringify(header));
          const encodedPayload = base64UrlEncode(JSON.stringify(userInformation));
          const token = `${encodedHeader}.${encodedPayload}`;
          refreshTokenSetup(res, token);

          localStorage.setItem('token', token)
          localStorage.setItem('access_token', res.access_token)
          location.reload();
        }
      }
    }
  }

  if(localStorage.getItem('token')) return <Redirect to='/'/>

  return (
    <div className={`login-page ${ theme_color }`} style={ theme_color === 'theme-dark' ? { backgroundImage: `url(${ HomeBackground })` } : null}>
      <div className="container">
        <img src={ theme_color === 'theme-dark' ? LogoWhite : Logo } alt="logo"/>

        <div className="content">

          <div className='left-column'>
            <div className="login-box">
              <h5>Welcome to <br/> Chris Arnold's Life!</h5><br/>
              <button onClick={() => login()}>Sign in with Google 🚀 </button>
            </div>
          </div>
          
          <div className='right-column'>
            <img src={ ProfilePic } alt=""/>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Login