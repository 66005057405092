import React, { useEffect, useState } from 'react'

const Select = React.memo(({ theme_color, values, title, pushFilterProp, multiselect, home, selected_value,  }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (value) => {
    setIsOpen(value)
  }

  const selectOption = (value) => {
    pushFilterProp(title, value.name, multiselect || false, true)
  }

  return (
    <div className={`select-container ${ theme_color } ${ home ? 'is-home' : ''}`} onClick={() => toggleDropdown(!isOpen)} tabIndex={0} onBlur={() => toggleDropdown(false)}>
      <div className="custom-select-wrapper" >
        <div className={ "custom-select " + (isOpen ? 'open' : '') } >

          <div className="custom-select__trigger">
            <span>{ selected_value || title }</span>
            <div className="custom-select_selected">

            </div>
            <div className="arrow"></div>
          </div>
          <div className="custom-options">
            { 
              values && values.length ?
                values.map((value, index) => {
                  return <button key={ index } onMouseDown={() => selectOption(value)}><span className="custom-option" data-value={ value.name.toLowerCase() }>{ value.name }</span></button>
                })
              : null
            }
          </div>
        </div>
      </div>
    </div>
  )
})
export default Select