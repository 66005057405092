import React, { useCallback, useEffect, useState } from "react"
import { confirmAlert } from 'react-confirm-alert'; 
import Modal from 'react-modal';
import svgs from '../utils/svgs';
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react'; 
import SelectReact from './../components/SelectReact';
import jwt_decode from "jwt-decode";
import * as htmlToImage from 'html-to-image';
import ThreeDotsWave from "../components/ThreeDotsLoading";

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    height: '100%',
    width: '100%',
  },
  overlay: {
    zIndex: 3
  }
};

Modal.setAppElement(document.createElement('div'));
const csrf_token = document.getElementsByName('csrf-token')[0].content;

axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token;

const HandleQuote = React.memo(({ theme_color, button_title, button_classes, data, user, add_quote, edit_quote, editQuoteProp, removeQuoteProp, addQuoteProp, prefillFiltersProp, selectedFiltersProp, generateImageQuoteProp }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quote_title, setQuoteTitle] = useState('');
  const [quote_page, setQuotePage] = useState('');
  const [quote_description, setQuoteDescription] = useState('');
  const [filters, setFilters] = useState([])
  const [status, setStatus] = useState(null)

  customStyles.content = {...customStyles.content, backgroundColor: (theme_color === 'theme-dark' ? '#162030' : 'white') }

  useEffect(() => {
    if(edit_quote){
      setQuoteTitle(data.title);
      setQuoteDescription(data.description)
      setQuotePage(data.page || '')

      //we set filters if exists
      if(selectedFiltersProp){
        let newArrFilters = [];

        if(selectedFiltersProp.authors && selectedFiltersProp.authors.length){
          let ids = [];
          selectedFiltersProp.authors.map((author) => {
            ids.push(author.value)

            return ids;
          })

          newArrFilters.push({ type: 'Authors', ids:ids })
        }

        if(selectedFiltersProp.tags && selectedFiltersProp.tags.length){
          let ids = [];
          selectedFiltersProp.tags.map((tag) => {
            ids.push(tag.value)

            return ids;
          })

          newArrFilters.push({ type: 'Tags', ids:ids })
        }

        if(selectedFiltersProp.categories && selectedFiltersProp.categories.length){
          let ids = [];
          selectedFiltersProp.categories.map((category) => {
            ids.push(category.value)

            return ids;
          })

          newArrFilters.push({ type: 'Categories', ids:ids })
        }

        setFilters(newArrFilters);
      }
    }
  },[])

  function openModal() {
    if(add_quote){
      setQuoteTitle('');
      setQuoteDescription('');
      setQuotePage('');
      setFilters([]);
    }
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function createQuoteFilterParams(type){
    var filterIds = filters.find(filter => filter.type == type) ? filters.find(filter => filter.type == type).ids : [];
    var quotes_params = [];

    if(filterIds.length){
      filterIds.map(filter => {
        if(prefillFiltersProp[type.toLowerCase()].find(prefill => prefill.value === filter)){
          quotes_params.push(prefillFiltersProp[type.toLowerCase()].find(prefill => prefill.value === filter).label)
        } else {
          quotes_params.push(filter)
        }
      })
    }

    return quotes_params
  }

  async function addQuote(){

    var quote_tags = await createQuoteFilterParams('Tags');
    var quote_authors = await createQuoteFilterParams('Authors');
    var quote_categories = await createQuoteFilterParams('Categories');
    var quote_sources = await createQuoteFilterParams('Sources');

    let generate_tags = quote_tags.find(qt => qt.substring(0,2) === 'A+');

    if(!quote_title.length){
      setStatus('The quote headline cannot be empty!') 
      return;
    }

    if(!quote_description.length){
      setStatus('The quote description cannot be empty!')
      return;
    }
    
    let { data } = await axios.post('/api/v1/quotes.json',{
      quote_title: quote_title,
      quote_description: quote_description,
      quote_page: quote_page,
      quote_tags: quote_tags.length ? quote_tags : null,
      quote_authors: quote_authors.length ? quote_authors : null,
      quote_categories: quote_categories .length ? quote_categories : null,
      quote_sources: quote_sources.length ? quote_sources : null,
    })

    await addQuoteProp(data)

    if(data.data && generate_tags){
      await generateQuoteImg(data.data.id)
    }

    closeModal()
  }

  async function editQuote(){
    var quote_tags = await createQuoteFilterParams('Tags');
    var quote_authors = await createQuoteFilterParams('Authors');
    var quote_categories = await createQuoteFilterParams('Categories');
    var quote_sources = await createQuoteFilterParams('Sources');

    let generate_tags = quote_tags.find(qt => qt.substring(0,2) === 'A+');

    if(!quote_title.length) return setStatus('The quote headline cannot be empty!') 
    if(!quote_description.length) return setStatus('The quote description cannot be empty!')

    let data_edit = await axios.post('/api/v1/edit-quote-action.json',{
                    quote_title: quote_title,
                    quote_description: quote_description,
                    quote_page: quote_page,
                    quote_id: data.id,
                    quote_tags: quote_tags.length ? quote_tags : null,
                    quote_authors: quote_authors.length ? quote_authors : null,
                    quote_categories: quote_categories.length ? quote_categories : null,
                    quote_sources: quote_sources.length ? quote_sources : null
                  })

    if((quote_title !== data.title || quote_description !== data.description || !data.generated_url) && generate_tags && data_edit.data) await generateQuoteImg(data_edit.data.data.id)
    
    editQuoteProp(data_edit.data.data)
    closeModal()
  }

  async function deleteQuote(){
    setLoading(true);
    await axios.delete('/api/v1/quotes/' + data.id + '.json')
      .then(_ => {
        removeQuoteProp(data.id);
      })
  }

  const generateQuoteImg = async (quote_id) => {
      var accessToken = localStorage.getItem('access_token') //get accessToken from localstorage
      var generatedImage = document.querySelector('.generate-preview')

      if(accessToken && generatedImage){
        await htmlToImage.toJpeg(generatedImage)
          .then(async function(dataUrl) {
            await fetch(dataUrl)
              .then(res => res.blob())
              .then(async blob => {
                const file = new File([blob], "File name",{ type: "image/png" })

                var formData = new FormData();
    
                formData.append('file', file);
                formData.append('quote_id', quote_id)
                formData.append('accessToken', accessToken)
  
                await axios.post('/api/v1/upload-google-albums', formData)
                  .then(res_generate => {
                    generateImageQuoteProp(quote_id, res_generate.data.generated_at, res_generate.data.generated_url, res_generate.data.updated_at)
                  })
              })
          })
          .catch(function (error) {
            console.log('oops, something went wrong!', error);
          });
      }
  }

  function deleteQuoteTry(){
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteQuote()
        },
        {
          label: 'No'
        }
      ]
    });
  }

  async function submit(){
    setLoading(true);

    if(add_quote){
      await addQuote();
    } else if(edit_quote) { //edit dream modal
      await editQuote();
    }

    setLoading(false)
  }

  async function pushFilter(value, type){
    let newArrFilters = [...filters];
    let indexType = newArrFilters.findIndex(naf => naf.type == type);
    
    if(indexType > -1){
      newArrFilters[indexType].ids.push(value)
    } else {
      newArrFilters.push({ type: type, ids:[value] })
    }

    setFilters(newArrFilters)
  }

  async function removeFilter(value, type){
    let newArrFilters = [...filters];

    if(value === 'all_values'){
      newArrFilters = newArrFilters.filter(naf => naf.type !== type);
    } else {
      let indexType = newArrFilters.findIndex(naf => naf.type === type);
  
      if(indexType > -1){
        if(newArrFilters[indexType].ids.length === 1){
          newArrFilters = newArrFilters.filter(naf => naf.type !== type);
        } else {
          newArrFilters[indexType].ids = newArrFilters[indexType].ids.filter(id => id !== value);
        }
      }
    }

    setFilters(newArrFilters)
  }

  //Remove HTML Tags
  function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();

    return str.replace( /(<([^>]+)>)/ig, '');
  }

  return (
    <div>
      <button className={ button_classes } onClick={() => openModal()}>
        { add_quote && button_title }
        { edit_quote && <h3 className="title">{ button_title }</h3>}
      </button>
      {
        modalIsOpen && 
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            htmlOpenClassName="handle-quote"
          >
            <div className={`head ${theme_color}`}>
              <div className="back-buttons">
                <button onClick={() => closeModal()} className="primary">
                  { svgs.back_arrow }
                  Quotes
                </button>
                {/* <span className="separator">|</span>
                <span className="secondary">draft</span> */}
              </div>
              {
                status ? <p className={`status ${ theme_color }`}>{ status }</p> : null
              }
              <div className="action-buttons">
                {
                  add_quote ? 
                    <button className={`button transparent ${ theme_color }`} onClick={() => submit()} disabled={ loading } style={ loading ? { opacity: 0.7 } : null }>
                      {
                        loading ? <ThreeDotsWave/> : 'Publish Post'
                      }
                    </button> 
                  : null
                }
                {
                  edit_quote && <>
                                  { user && user.role === 0 ? <button className={`button transparent ${ theme_color }`} onClick={() => deleteQuoteTry()}>Delete Quote</button> : null }
                                  <button className={`button transparent ${ theme_color }`} onClick={() => submit()}>Save Changes</button>
                                </>
                }
              </div>
            </div>

            <div className={`content ${ theme_color }`}>
              <div className="tiny-content">
                <input className="quote-title" placeholder="Quote headline" onChange={event => { setQuoteTitle(event.target.value); setStatus('') }} value={ quote_title }/>
              </div>
              <div className="form-attributes first-row">
                <div className="box-action">
                  <SelectReact
                    multiselect = { false }
                    placeholder = "Sources"
                    addNewValues = { true }
                    options = { prefillFiltersProp.sources }
                    pushFilterProp = { pushFilter }
                    removeFilterProp = { removeFilter }
                    selectedFiltersProp = { selectedFiltersProp }
                    theme_color = { theme_color }
                  />
                </div>
                <div className="box-action">
                  <input className="quote-page" placeholder="Page" onChange={event => { setQuotePage(event.target.value); setStatus('') }} value={ quote_page }/>
                </div>
              </div>
              <div className="form-attributes">
                <div className="box-action">
                  <SelectReact
                    multiselect = { false }
                    placeholder = "Authors"
                    addNewValues = { true }
                    options = { prefillFiltersProp.authors }
                    pushFilterProp = { pushFilter }
                    removeFilterProp = { removeFilter }
                    selectedFiltersProp = { selectedFiltersProp }
                    theme_color = { theme_color }
                  />
                </div>
                <div className="box-action">
                  <SelectReact
                    multiselect = { true }
                    placeholder = "Tags"
                    addNewValues = { true }
                    options = { prefillFiltersProp.tags }
                    pushFilterProp = { pushFilter }
                    removeFilterProp = { removeFilter }
                    selectedFiltersProp = { selectedFiltersProp }
                    theme_color = { theme_color }
                  />
                </div>
                <div className="box-action">
                  <SelectReact
                    multiselect = { false }
                    placeholder = "Categories"
                    addNewValues = { true }
                    options = { prefillFiltersProp.categories }
                    pushFilterProp = { pushFilter }
                    removeFilterProp = { removeFilter }
                    selectedFiltersProp = { selectedFiltersProp }
                    theme_color = { theme_color }
                  />
                </div>
              </div>
              <div className="tiny-content">
                <Editor
                  apiKey="83h704apqy5jaskvr6onqcib9mmgbg0s4wc6zlu89kmlnvdr"
                  // initialValue={ }
                  value={quote_description.replace(/<\/?span[^>]*>/g,"")}
                  init={{
                    placeholder: "Quote Description",
                    height: 200,
                    menubar: false,
                    branding: false,
                    toolbar: false,
                    statusbar: false,
                    selector: "textarea",
                    plugins: [
                      'autolink',
                      'lists advlist',
                      'codesample',
                      'link',
                      'lists',
                      'media',
                      'table',
                      'image',
                      'quickbars',
                      'codesample',
                      'help',
                      'paste',
                    ],
                    content_style: 'body { margin: 0 }' + `div { font-weight: 600; color: ${ theme_color === 'theme-dark' ? '#749299' : '#222'}; }` + `p { font-weight: 600; color: ${ theme_color === 'theme-dark' ? '#749299' : '#222'}; }` + `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { font-weight: 600; color: ${ theme_color === 'theme-dark' ? '#749299' : '#222'}; }`,
                    quickbars_insert_toolbar: '',
                    quickbars_selection_toolbar: 'bold italic underline',
                    contextmenu: '',
                    browser_spellcheck: true
                  }}
                  onEditorChange={(value) => { setQuoteDescription(value); setStatus(''); }}
                />
              </div>

              <div className="generate-preview">
                <h4>{ quote_title }</h4>
                <p className={ removeTags(quote_description).length > 140 ? 'small' : ''}>{ removeTags(quote_description) }</p>
                {
                  filters.find(filter => filter.type === 'Authors') ?
                    <p className="author">by { typeof(filters.find(filter => filter.type === 'Authors').ids[0]) === 'number' ? prefillFiltersProp.authors.find(a => a.value === filters.find(filter => filter.type === 'Authors').ids[0]).label : filters.find(filter => filter.type === 'Authors').ids[0]}</p>
                  : null
                }
              </div>
            </div>
          </Modal>
      }
    </div>
  );
})

export default HandleQuote