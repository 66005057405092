import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { useLocation, Redirect } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'

import Header from './../components/Header';
import Search from './../components/Search';
import Select from './../components/Select';
import SelectReactSimple from '../components/SelectReactSimple';
import SearchLibrary from '../modals/SearchLibrary';
import HandleLibrary from '../modals/HandleLibrary';
import RecomendLibrary from '../modals/RecomendLibrary';
import ThreeDotsWave from '../components/ThreeDotsLoading';
// import Books from '../utils/all_books.json';
// import Documentaries from '../utils/all_doc.json';

import Hero from '../../../assets/images/hero-background.jpg';
import svgs  from './../utils/svgs'


const Libraries = ({ theme_color, changeTheme, user }) => {
  const routeParams = useLocation().state

  const [librariesState, setLibraries] = useState([])
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [archives, setArchives] = useState([]);
  const [filters, setFilters] = useState([]);
  const [viewType, setViewType] = useState('list');
  const [libraryType, setLibraryType] = useState(routeParams && routeParams.library_type === 1 ? 'documentaries' : 'books')
  const [readMore, setReadMore] = useState([]);
  const [openAutoEdit, setOpenAutoEdit] = useState({})
  const [resetSearch, setResetResearch] = useState(false);
  const [countLibraries, setCountLibraries] = useState(0);

  const librariesStateRef = useRef();
  librariesStateRef.current = librariesState;

  const libraryTypeRef = useRef();
  libraryTypeRef.current = libraryType

  const filtersRef = useRef();
  filtersRef.current = filters

  const loadingMoreRef = useRef();
  loadingMoreRef.current = loadingMore

  const loadingRef = useRef();
  loadingRef.current = loading

  const countLibrariesRef = useRef();
  countLibrariesRef.current = countLibraries

  const sortByValues = useMemo(() => {
    return [{ name: 'Alphabetically (desc)' }]
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[librariesState])

  useEffect(() => {
    if(!routeParams){
      getLibraries(null)
    }
    getLibrariesPrefillFilters(routeParams && routeParams.library_type === 1 ? 6 : 5 )

  },[setLibraries])

  useEffect(() => {
    getcountLibraries('books')
  },[])

  const getcountLibraries = async (type) => {
    await axios.get(`/api/v1/count-libraries?type=${ type }`)
      .then(res => {
        if(res.data.success){
          setCountLibraries(res.data.count)
        }
      })
  }

  async function handleScroll() {
    if ((window.innerHeight + document.documentElement.scrollTop !== document.querySelector('body>div').offsetHeight)) return;
    if(!loadingMoreRef.current && !loadingRef.current) return await loadMore();
  }

  async function loadMore(){
    setLoadingMore(true)
    let new_arr = [...filters]
    let filters_url = '?offset=' + librariesState.length + '&type=' + (libraryType === 'books' ? '0' : '1');

    if(new_arr.find(na => na.title === 'Tags')){
      filters_url += `&filter_by_tags=${ encodeURIComponent(new_arr.find(na => na.title === 'Tags').values[0]) }`
    }

    if(new_arr.find(na => na.title === 'Search')){
      filters_url += `&search=${ encodeURIComponent(new_arr.find(na => na.title === 'Search').values[0].toLowerCase()) }`
    }

    if(new_arr.find(na => na.title === 'Category')){
      filters_url += `&filter_by_category=${ encodeURIComponent(new_arr.find(na => na.title === 'Category').values.join('-')) }`
    }

    if(new_arr.find(na => na.title === 'Archive')){
      filters_url += `&filter_by_archive=${ new_arr.find(na => na.title === 'Archive').values.join() }`
    }

    if(new_arr.find(na => na.title === 'Sort By')){
      filters_url += `&sort_by=${ new_arr.find(na => na.title === 'Sort By').values[0].toLowerCase() }`
    }

    await axios.get('/api/v1/libraries.json' + filters_url)
      .then(res=> {
        var more_quotes = [];

        res.data.data.forEach(quote => {
          if(!librariesState.find(current => current.id == quote.id)){
            more_quotes.push(quote);
          }
        })
        setLibraries(currentQuotes => [...currentQuotes,...more_quotes])
      })

    setLoadingMore(false)
  }

  const addLibrary = useCallback(async (library) => {
    let newArr = [...librariesStateRef.current]
    
    newArr.unshift(library.data.data);
    setLibraries(newArr);
    refreshFilters(libraryTypeRef.current)
    setCountLibraries(countLibrariesRef.current+1)
    setOpenAutoEdit({ status: true, id: library.data.data.id });
  },[])

  const editLibrary = useCallback((library) => {
    let newArr = [...librariesStateRef.current]
    let indexDream = newArr.findIndex(d => parseInt(d.id) == library.id)

    newArr[indexDream] = library;
    setLibraries(newArr)
    refreshFilters(libraryTypeRef.current)
  }, [])

  const removeLibrary = useCallback((id) => {
    let newArr = [...librariesStateRef.current]

    newArr = newArr.filter(d => parseInt(d.id) !== parseInt(id))

    setLibraries(newArr)
    setCountLibraries(countLibrariesRef.current-1)
    refreshFilters(libraryTypeRef.current)
  }, []);

  const pushFilter = useCallback((title, value, multiselect, submit) => {
    let new_arr = [...filtersRef.current];

    //take index of title filter (title represent the category of entire filter)
    let indexFilter = new_arr.findIndex(na => na.title === title);

    if(indexFilter < 0){
      if(value){
        new_arr.push({ title: title, values: [value]})
      }
    } else {
      let indexValue = new_arr[indexFilter].values.findIndex(v => v === value);

      if(indexValue < 0){
        if(!multiselect){
          if(value){
            new_arr[indexFilter].values = [value]
          } else {
            new_arr = new_arr.filter((_,index) => index !== indexFilter);
          }
        } else {
          new_arr[indexFilter].values.push(value)
        }
      }
    }

    let filters_url = '?type=' + (libraryTypeRef.current === 'books' ? '0' : '1');

    if(new_arr.find(na => na.title === 'Tags')){
      filters_url += `&filter_by_tags=${ encodeURIComponent(new_arr.find(na => na.title === 'Tags').values[0]) }`
    }
    
    if(new_arr.find(na => na.title === 'Search')){
      filters_url += `&search=${ encodeURIComponent(new_arr.find(na => na.title === 'Search').values[0].toLowerCase()) }`
    }
    
    if(new_arr.find(na => na.title === 'Category')){
      filters_url += `&filter_by_category=${ encodeURIComponent(new_arr.find(na => na.title === 'Category').values.join('-')) }`
    }

    if(new_arr.find(na => na.title === 'Archive')){
      filters_url += `&filter_by_archive=${ new_arr.find(na => na.title === 'Archive').values.join() }`
    }

    if(new_arr.find(na => na.title === 'Sort By')){
      filters_url += `&sort_by=${ new_arr.find(na => na.title === 'Sort By').values[0].toLowerCase() }`
    }

    if(submit){
      getLibraries(filters_url)
    }

    setFilters(new_arr)
  },[])

  const getLibraries = async (filters) => {
    setLoading(true)
    let url = `/api/v1/libraries.json${ filters ? filters : ('?type=' + (libraryType === 'books' ? 0 : 1))}`
    await axios.get(url)
      .then(res=>{
        setLibraries(res.data.data)
      })

    setLoading(false)
  }

  const getLibrariesPrefillFilters = (type) => {
    axios.get(`/api/v1/prefill-filters-libraries.json?type=${ type }&library_type=${ type === 5 ? 0 : 1}`)
      .then(res=>{
        if(res.data.archives){
          let archivesOptions = [];

          var start = moment(res.data.archives.first_month, 'YYYY-MM-DD')
          var end = moment(res.data.archives.last_month, 'YYYY-MM-DD')

          if(res.data.archives.first_month !== res.data.archives.last_month){
            while (start < end) {
              archivesOptions.push({ value: start.format('MMMM YYYY'), label: start.format('MMMM YYYY')})
              start.add(1, 'month')
            }
          } else {
            archivesOptions.push({ value: start.format('MMMM YYYY'), label: start.format('MMMM YYYY')})
          }

          setArchives(archivesOptions)
        }

        if(res.data.categories){
          let categoriesOptions = [];

          res.data.categories.forEach(a => {
            categoriesOptions.push({ value: a.id, label: a.title})
          })

          setCategories(categoriesOptions);
        }

        if(res.data.tags){
          let tagsOptions = [];

          res.data.tags.forEach(a => {
            tagsOptions.push({ value: a.id, label: a.title})
          })

          setTags(tagsOptions);
        }
      })
  }

  const clearFilters = () => {
    setFilters([])
    setResetResearch(true);
    setTimeout(() => {
      setResetResearch(false);
    }, 200)
    if(!filters.find(f => f.title === 'Search')){
      getLibraries()
    }
  }

  const handleLibraryType = (type) => {
    setLibraries([]);
    setLibraryType(type)
    getLibraries(type === 'books' ? ('?type=0') : ('?type=1'))
    getcountLibraries(type)
    refreshFilters(type);
  }

  const refreshFilters = (type) => {
    setCategories([]);
    setTags([]);
    setArchives([]);
    setFilters([]);

    getLibrariesPrefillFilters(type === 'books' ? 5 : 6);
  }

  //Remove HTML Tags
  function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();

    return str.replace( /(<([^>]+)>)/ig, '');
  }

  const readMoreHandle = (id) => {
    if(readMore.find(item => item.id === id)){
      setReadMore(
        readMore.map(item => 
            item.id == id
            ? {...item, status: !item.status} 
            : item 
      ))
    } else {
      setReadMore([...readMore, { status: true, id: id }])
    }
  }

  if(!localStorage.getItem('token')) return <Redirect to="/login"/>
  return (
    <div className="libraries-page">
      <div className={`hero ${ theme_color }`} style={ theme_color === 'theme-dark' ? { backgroundImage: `url(${ Hero })` } : null}>
        <div className="container">

          <Header theme_color={ theme_color } changeTheme={ changeTheme } user={ user }/>

          <div className={`top-content ${ theme_color }`}>
            <h1>Library</h1>
            <div className="search-form">
              {
                librariesState.length || filters ? 
                  <Search 
                    placeholder={ libraryType === 'books' ? 'Search books' : 'Search documentaries' }
                    pushFilterProp= { pushFilter }
                    books_status = { libraryType === 'books'}
                    prefillValueProp = { routeParams && routeParams.search_param ? routeParams.search_param : null}
                    reset = { resetSearch }
                  />
                : null
              }
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="library-types">
          <button className={`button ${ theme_color } ${libraryType == 'documentaries' ? 'transparent' : ''}`} onClick={() => handleLibraryType('books')}>Books</button>
          <button className={`button ${ theme_color } ${libraryType == 'books' ? 'transparent' : ''}`} onClick={() => handleLibraryType('documentaries')}>Documentaries</button>
        </div>
        <div className={`actions-container ${ theme_color }`}>

          <div className="left-column">
            {/* {
              librariesState.length || filters ?
                <Select
                  title="Archive"
                  values={ archives.map(a => {a.name = a.label; return a }) }
                  pushFilterProp= { pushFilter }
                  theme_color={ theme_color }
                /> : null
            } */}

            {
              librariesState.length || filters ?
                <SelectReactSimple
                  multiselect = { true }
                  placeholder = "Tags"
                  addNewValues = { false }
                  options = { tags.map(t => { t.name = t.label; return t }) }
                  pushFilterProp = { pushFilter }
                  theme_color = { theme_color }
                /> : null
            }

            <Select
              title="Sort By"
              values={ sortByValues }
              pushFilterProp= { pushFilter }
              theme_color={ theme_color }
            />

            {/* {
              librariesState.length || filters ?
                <SelectReactSimple
                  multiselect = { false }
                  placeholder = "Category"
                  addNewValues = { false }
                  options = { categories.map(t => { t.name = t.label; return t }) }
                  pushFilterProp = { pushFilter }
                  theme_color = { theme_color }
                /> : null
            } */}
          </div>

          <div className="right-column">
            {
              viewType === 'list' ?
                <button onClick={() => setViewType('gallery')} className="view-types"><span>{ svgs.gallery }</span>Gallery View</button>
              : 
                <button onClick={() => setViewType('list')} className="view-types"><span>{ svgs.list }</span>List View</button>
            }
            <SearchLibrary
              button_title = { libraryType === 'books' ? 'Add New Book' : 'Add New Documentary' }
              button_classes = {`button add_book ${ theme_color }`}
              books_status = { libraryType === 'books' }
              addLibraryProp = { addLibrary }
            />
          </div>
        </div>

        {
          filters.length ?
            <div className={`actions-filters ${ theme_color }`}>
              <button className={`button transparent ${ theme_color }`} onClick={() => clearFilters()}>Clear all filters</button>

              {
                filters.map((filter,index) => (
                  <div className="filter-element" key={ index }>
                    <span className="title">{ filter.title }</span>
                    {
                      filter.values.map((v,index) => (
                        <button className="border" key={ index }>{ v }</button>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          : null
        }

        <ul className={ `library-list ${ theme_color }` + (viewType === 'list' ? ' list' : ' gallery')}>
          {
            countLibraries ? 
              <div className="total"><p>Total:<span>{ countLibraries }</span></p></div>
            : null
          }
          {
            loading ? 
              <div className="loading">
                <ThreeDotsWave color={ theme_color === 'theme-white' ? 'black' : 'white' }/>
              </div>
            :
              librariesState.length ?
                librariesState.map((library, index) => {
                    return (
                            <li className="library" key={library.id}>

                              {
                                library.attributes.authors && library.attributes.authors.length && viewType === 'gallery' ? 
                                  <div className="author-gallery">
                                    <p>by { library.attributes.authors[0].title }</p>
                                  </div> : null
                              }

                              <div className="library-img gallery" style={{ backgroundImage: `url(${ library.attributes.url })` }}>
                              </div>

                              <div className="library-content">
                                <div className="library-actions">
                                  {
                                    library.attributes.authors && library.attributes.authors.length && viewType == "list" ? 
                                      <div className="library-author">
                                        <p className="author">Author:</p>
                                        <p className="author-name">{ library.attributes.authors[0].title }</p>
                                      </div>
                                    : null
                                  }
                                  {
                                    viewType == 'list' || viewType === 'gallery' ?
                                      <div className="library-edit">
                                        <HandleLibrary
                                          button_title = 'Edit'
                                          button_classes = { `handle-library ${ theme_color }` }
                                          theme_color = { theme_color }
                                          books_status = { libraryType === 'books' }
                                          id = { library.id }
                                          removeLibraryProp = { removeLibrary }
                                          editLibraryProp = { editLibrary }
                                          author = { library.attributes.authors && library.attributes.authors.length ? library.attributes.authors[0] : false}
                                          image = { library.attributes.url || false }
                                          prefillFiltersProp = {{
                                            categories: categories,
                                            tags: tags
                                          }}
                                          selectedFiltersProp = {{
                                            categories: library.attributes.categories ? library.attributes.categories.map(category => ({ value: category.id, label: category.title })) : [],
                                            tags: library.attributes.tags ? library.attributes.tags.map(tag => ({ value: tag.id, label: tag.title })) : [],
                                          }}
                                          openAutoEditProp = { openAutoEdit }
                                          user = { user }
                                        />
                                      </div>
                                    : null
                                  }
                                </div>
                                <h5 className='title'>{ library.attributes.title }</h5>
                                { 
                                  library.attributes.description && viewType == "list" ? 
                                    <p className="description">
                                      { 
                                        removeTags(library.attributes.description).length > 360 ? 
                                          readMore.find(item => item.id === library.id) && readMore.find(item => item.id === library.id).status ? 
                                            removeTags(library.attributes.description)
                                          :
                                            removeTags(library.attributes.description).substring(0,359) 
                                        : 
                                          removeTags(library.attributes.description)  
                                      }
                                      { removeTags(library.attributes.description).length > 360 ? <span onClick={() => readMoreHandle(library.id)} className="read-or-hide">{ readMore.find(item => item.id === library.id) && readMore.find(item => item.id === library.id).status ? ' show less' : ' ...read more'}</span> : null }
                                    </p> 
                                  : null 
                                }

                                <div className='bottom-actions'>
                                  {
                                    library.attributes.link_url ? 
                                      <a href={ library.attributes.link_url } target="_blank" className={`button ${ theme_color }`}>
                                        View On { library.attributes.type_table === 0 ? 'Google' : library.attributes.link_url.indexOf('apple') > -1 ? 'Apple' : 'IMDB'}
                                      </a> 
                                    : null
                                  }
                                  
                                  <RecomendLibrary
                                    button_title = { `Recomend This ${ libraryType === 'books' ? 'Book' : 'Documentary' }` }
                                    books_status = { libraryType === 'books' }
                                    button_classes = {`button transparent ${ theme_color } recomend-library`} 
                                    theme_color = { theme_color }
                                    data = { library }
                                  />
                                </div> 
                              </div>
                            </li>
                    )
                  })
              : <center>Unfortunately no { libraryType === 'books' ? 'books' : 'documentaries'} were found!</center>
          }
        </ul>

        {
          loadingMore && !loading ? 
            <div className="loading absolute">
              <ThreeDotsWave/>
            </div>
          : null
        }
        
      </div>
    </div>
  );
}

export default Libraries