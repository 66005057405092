import React, { useCallback, useEffect, useState } from "react"
import { confirmAlert } from 'react-confirm-alert'; 
import Modal from 'react-modal';
import svgs from '../utils/svgs';
import axios from "axios";

import ThreeDotsWave from "../components/ThreeDotsLoading";
import SelectReact from "../components/SelectReact"; 

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 3
  }
};

Modal.setAppElement(document.createElement('div'));
const csrf_token = document.getElementsByName('csrf-token')[0].content;

axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token;

const HandleLibrary = React.memo(({ theme_color, button_title, button_classes, user, author, books_status, prefillFiltersProp, selectedFiltersProp, id, removeLibraryProp, editLibraryProp, openAutoEditProp, image }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEditBook, setLoadingEditBook] = useState(null);
  const [status, setStatus] = useState('');
  const [filters, setFilters] = useState([]);
  const [editCover, setEditCover] = useState(false);
  const [currentCover, setCurrentCover] = useState(image);
  const [covers, setCovers] = useState([]);

  customStyles.content = {...customStyles.content, width: (window.innerWidth < 768 ? '90%' : '700px'), height: (window.innerHeight < 768 ? '300px' : 'auto')}

  useEffect(() => {
    if(selectedFiltersProp){
      let newArrFilters = [];

      if(selectedFiltersProp.tags && selectedFiltersProp.tags.length){
        let ids = [];
        selectedFiltersProp.tags.map((tag) => {
          ids.push(tag.value)

          return ids;
        })

        newArrFilters.push({ type: 'Tags', ids:ids })
      }

      if(selectedFiltersProp.categories && selectedFiltersProp.categories.length){
        let ids = [];
        selectedFiltersProp.categories.map((category) => {
          ids.push(category.value)

          return ids;
        })

        newArrFilters.push({ type: 'Categories', ids:ids })
      }

      setFilters(newArrFilters);
    }

    if(image){
      handleCovers();
    }
  },[])

  useEffect(() => {
    if(openAutoEditProp.status && openAutoEditProp.id === id){
      openModal()
    }
  }, [openAutoEditProp])

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setEditCover(false)
  }

  const handleCovers = () => {
    const zooms = [1, 2, 3, 4, 5, 6];
    const index = image.indexOf('&zoom=');
    const current_zoom = parseInt(image[index + 6]);

    var covers = []
    for(let zoom of zooms){
      var str = image;
      covers.push(str.substr(0,index + 6) + zoom + str.substr(index + 7));
    }

    setCovers(covers);
  }

  async function createQuoteFilterParams(type){
    var filterIds = filters.find(filter => filter.type == type) ? filters.find(filter => filter.type == type).ids : [];
    var libraries_params = [];

    if(filterIds.length){
      filterIds.map(filter => {
        if(prefillFiltersProp[type.toLowerCase()].find(prefill => prefill.value === filter)){
          libraries_params.push(prefillFiltersProp[type.toLowerCase()].find(prefill => prefill.value === filter).label)
        } else {
          libraries_params.push(filter)
        }
      })
    }

    return libraries_params
  }

  const submitEditBook = async () => {
    setLoadingEditBook(true);
    var library_categories = await createQuoteFilterParams('Categories');
    var library_tags = await createQuoteFilterParams('Tags');

    await axios.post('/api/v1/edit-libraries-action.json',{
      library_id: id,
      library_categories: library_categories.length ? library_categories : null,
      library_tags: library_tags,
      library_type: books_status ? 0 : 1,
      library_img: image !== currentCover ? currentCover : null
    })
      .then(res => {
        editLibraryProp(res.data.data)
        closeModal()
      })
    setLoadingEditBook(null);
  }

  async function pushFilter(value, type){
    let newArrFilters = [...filters];
    let indexType = newArrFilters.findIndex(naf => naf.type === type);

    if(indexType > -1){
      newArrFilters[indexType].ids.push(value)
    } else {
      newArrFilters.push({ type: type, ids:[value] })
    }

    setFilters(newArrFilters)
  }

  async function removeFilter(value, type){
    let newArrFilters = [...filters];

    if(value === 'all_values'){
      newArrFilters = newArrFilters.filter(naf => naf.type !== type);
    } else {
      let indexType = newArrFilters.findIndex(naf => naf.type === type);
  
      if(indexType > -1){
        if(newArrFilters[indexType].ids.length === 1){
          newArrFilters = newArrFilters.filter(naf => naf.type !== type);
        } else {
          newArrFilters[indexType].ids = newArrFilters[indexType].ids.filter(id => id !== value);
        }
      }
    }

    setFilters(newArrFilters)
  }

  async function deleteLibrary(){
    setLoadingDelete(true);
    await axios.delete('/api/v1/libraries/' + id + '.json')
      .then(_ => {
        setLoadingDelete(false)
        closeModal()
        removeLibraryProp(id);
      })
  }

  function deleteLibraryTry(){
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteLibrary()
        },
        {
          label: 'No'
        }
      ]
    });
  }

  return (
    <div>
      <button className={ button_classes } onClick={() => openModal()}>
        { svgs.edit }
        { button_title }
      </button>
      {
        modalIsOpen && 
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            htmlOpenClassName="handle-library"
          >
            <h3>{ books_status ? 'EDIT BOOK' : 'EDIT DOCUMENTARY' }</h3>
            <div className="content">
              <p className="title">Image</p>
              {
                editCover ? 
                  covers.length ?
                    <div className="covers-list">
                      {
                        covers.map((cover,index) => {
                          return <div key={ index } onClick={() => { setEditCover(false); setCurrentCover(cover) }} className="library-img" style={{ backgroundImage: `url(${ cover })` }}></div>
                        })
                      }
                    </div>
                  : null
                : 
                  <>
                    <div className="library-img" style={{ backgroundImage: `url(${ currentCover })` }}>
                    </div>
                    {
                      books_status ?
                        <button className="edit-image-btn" onClick={() => setEditCover(true)}>Edit</button>
                      : null
                    }
                  </>
              }
              {
                author && author.title ? 
                  <input type="text" name="library-author" value={ author.title } onChange={event => { setSearch(event.target.value); setStatus('') }} placeholder="Author" disabled={true}/> 
                : null
              }
              {/* <div className="library-filter">
                <SelectReact
                  multiselect = { true }
                  placeholder = "Categories"
                  addNewValues = { true }
                  options = { prefillFiltersProp.categories }
                  pushFilterProp = { pushFilter }
                  removeFilterProp = { removeFilter }
                  selectedFiltersProp = { selectedFiltersProp }
                  // theme_color = { theme_color }
                />
              </div> */}
              <div className="library-filter">
                <SelectReact
                  multiselect = { true }
                  placeholder = "Tags"
                  addNewValues = { true }
                  options = { prefillFiltersProp.tags }
                  pushFilterProp = { pushFilter }
                  removeFilterProp = { removeFilter }
                  selectedFiltersProp = { selectedFiltersProp }
                  // theme_color = { theme_color }
                />
              </div>
              { status ? <p className="status">{ status }</p> : null }

              <div className="button-actions">
                <button className="button upload-btn" onClick={() => submitEditBook()} disabled={ loadingEditBook || loadingDelete } style={ loadingEditBook || loadingDelete ? { opacity: 0.7 } : null }>
                  {
                    loadingEditBook ? <ThreeDotsWave/> : books_status ? 'Edit Book' : 'Edit Documentary'
                  }
                </button>
                {
                  user && user.role === 0 ?
                    <button className="button transparent" onClick={() => deleteLibraryTry()} disabled={ loadingEditBook || loadingDelete } style={ loadingEditBook || loadingDelete ? { opacity: 0.7 } : null }>
                      {
                        loadingDelete ? <ThreeDotsWave color={ 'black' }/> : 'Delete'
                      }
                    </button> : null
                }
              </div>
            </div>
          </Modal>
      }
    </div>
  );
})

export default HandleLibrary