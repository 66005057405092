export const refreshTokenSetup = (res, token) => {
  //Timing to renew access token
  let refreshTiming = (res.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    console.log(res)
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    console.log('New Auth Response:', newAuthRes);

    // Save new tokens
    localStorage.setItem('token', token)
    localStorage.setItem('access_token', newAuthRes.access_token)

    // Setup the other timer after the first one
    setTimeout(refreshToken, refreshTiming);
  }

  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming)
}