import React from 'react'
import { motion } from 'framer-motion';

const loadingContainer = {
  width: '2rem',
  height: '15px',
  display: 'flex',
  justifyContent: 'space-around',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)'
}

var loadingCircle = {
  display: 'block',
  width: '0.5rem',
  height: '0.5rem',
  backgroundColor: 'white',
  borderRadius: '0.25rem'
}

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.1
    }
  },
  end: {
    transition: {
      staggerChildren: 0.1
    }
  }
}

const loadingCircleVariants = {
  start: {
    y: '0%',
  },
  end: {
    y: '100%'
  }
}

const loadingCircleTransition = {
  duration: 0.4,
  yoyo: Infinity,
  ease: 'easeInOut'
}

export default function ThreeDotsWave({ color }){

  if(color){
    loadingCircle = {...loadingCircle, backgroundColor: color}
  }

  return (
    <motion.div 
      style={loadingContainer}
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      <motion.span 
        style={loadingCircle} 
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
      <motion.span 
        style={loadingCircle} 
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
      <motion.span 
        style={loadingCircle} 
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
    </motion.div>
  )
}