import React, { useEffect, useState, useRef } from 'react'
import svgs from './../utils/svgs';

const Search = React.memo(({ placeholder, pushFilterProp, books_status, home, submitSearchProp, prefillValueProp, reset }) => {
  const [search, setSearch] = useState('')
  const didMountRef = useRef(false);
  
  useEffect(() => {
    if(didMountRef.current){
      const timeoutId = setTimeout(() => {
        pushFilterProp('Search', search, false, true)
      }, 
      800);
      return () => clearTimeout(timeoutId);
    } else {
      didMountRef.current = true;
    }
  }, [search]);
  
  useEffect(() => {
    setSearch('')
  }, [books_status])
  
  useEffect(() => {
    if(prefillValueProp){
      submitSearch(prefillValueProp)
      window.history.replaceState({}, document.title)
    }
  },[])
  
  useEffect(() => {
    if(reset){
      setSearch('');
    }
  }, [reset])

  const submitSearch = (value) => {
    pushFilterProp('Search', value, false, false)
    setSearch(value)
  }

  return (
    <div className="input-container">
      <input type="text" value={search} placeholder={placeholder} onChange={event => submitSearch(event.target.value)}></input>
      <button>{ svgs.search }</button>
    </div>
  )
})


export default Search