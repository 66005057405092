import React, { useCallback, useEffect, useState } from "react"
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert'; 
import { useDropzone } from 'react-dropzone'
import heic2any from "heic2any";
import svgs from '../utils/svgs';
import axios from "axios";
import Calendar from 'react-calendar';
import moment from 'moment'
import ThumbnailDefault from '../../../assets/images/video-thumbnail.jpg';
import VideoThumbnail from 'react-video-thumbnail';
import ThreeDotsWave from "../components/ThreeDotsLoading";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 3
  }
};

Modal.setAppElement(document.createElement('div'));
const csrf_token = document.getElementsByName('csrf-token')[0].content;

axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token;

const HandleDream = React.memo(({ title, button_title, button_classes, user, data, complete_dream, edit_dream, add_dream, removeDreamProp, editDreamProp, addDreamProp, completeDreamProp }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [dzActiveBefore, setDzActiveBefore] = useState(true);
  const [dzActiveAfter, setDzActiveAfter] = useState(true);
  const [file, setFile] = useState(null);
  const [fileAfter, setFileAfter] = useState(null);
  const [dream_title, setDreamTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [dateCompleted, setDateCompleted] = useState(new Date());
  const [calendarStatus, setCalendarStatus] = useState(false);
  const [isVideoThumbnail, setIsVideoThumbnail] = useState(false);
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(false);

  customStyles.content = {...customStyles.content, width: (window.innerWidth < 768 ? '90%' : '700px')}

  useEffect(() => {
    if(edit_dream || complete_dream){
      setDreamTitle(data.title)
    }
  },[])

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    if(file) URL.revokeObjectURL(file.preview)
  }, [file]);

  useEffect(() => {
    setFile(false)

    if(modalIsOpen && data && data.media_before_url && (edit_dream || complete_dream)){
      setDzActiveBefore(false);
    } else {
      setDzActiveBefore(true);
    }

    if(modalIsOpen && data && data.media_after_url && data.media_after_type && data.media_after_type.indexOf('video') > -1){
      setIsLoadingThumbnail(true)
    }
    
    if(modalIsOpen && data && data.media_after_url){
      setDateCompleted(new Date(data.completed_at))
      setDzActiveAfter(false);
    } else {
      setDzActiveAfter(true);
    }
  }, [modalIsOpen])

  //dropzone for after column
  const dropzoneAfter = useDropzone({
    onDrop: (file) => onDropAfter(file),
    maxFiles: 1,
    accept: ".png,.jpg,.gif,.bmp,.jpeg,.heic,.mp4,.mkv,.wmv,.m4v,.mov"
  })

  //dropzone for before column
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (file) => onDrop(file),
    maxFiles: 1,
    accept: ".png,.jpg,.gif,.bmp,.jpeg,.heic"
  })

  const onDropAfter = useCallback(async acceptedFiles => {
    setStatus('')
    let new_file = acceptedFiles[0];
    
    if(new_file.name && new_file.name.indexOf('.heic') > -1){
      setLoading(true);
      new_file = await convertHeicToJpg(new_file);
      setLoading(false);
    } else {
      Object.assign(new_file, { preview: URL.createObjectURL(new_file) })
    }
    
    setFileAfter(new_file);
    if(new_file.type.indexOf('image') < 0){
      setIsVideoThumbnail(true);
      setIsLoadingThumbnail(true);
    }
    setDzActiveAfter(false);
  }, [])
  
  const onDrop = useCallback(async acceptedFiles => {
    setStatus('')
    let new_file = acceptedFiles[0];

    if(new_file.name && new_file.name.indexOf('.heic') > -1){
      setLoading(true);
      new_file = await convertHeicToJpg(new_file);
      setLoading(false);
    } else {
      Object.assign(new_file, { preview: URL.createObjectURL(new_file) })
    }

    setFile(new_file);
    setDzActiveBefore(false);
  }, [])

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    if(dream_title.length || add_dream){
      setIsOpen(false);
      setCalendarStatus(false)
    }
  }

  async function addDream(){
    var formData = new FormData();

    if(!dream_title.length) return setStatus("It's mandatory to have a dream title.");
    if(!file) return setStatus("It's mandatory to have a before photo.");

    formData.append('file', file);
    formData.append('dream_title', dream_title);

    await axios.post('/api/v1/dreams.json',formData, {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      addDreamProp(res)
      closeModal()
    })
  }

  async function editDream(){
    if(dream_title.length){
      if(data.status && !fileAfter && dzActiveAfter){
        setStatus("This dream is already completed. It's mandatory to have an after photo.");
        return;
      }
      var formData = new FormData();

      formData.append('dream_title', dream_title);
      formData.append("dream_id", data.id);
      formData.append("dream_completed_at", dateCompleted)
      if(file){
        formData.append('file_before', file);
      }
      if(fileAfter){
        formData.append('file_after', fileAfter);
      }

      await axios.post('/api/v1/edit-dream-action.json',formData, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          editDreamProp(res.data.data)
          closeModal()
        })
    } else {
      setStatus("It's mandatory to have a dream title.")
    }
  }

  async function completeDream(){
    if(fileAfter){
      var formData = new FormData();

      formData.append('file', fileAfter);
      formData.append("dream_id", data.id);
      formData.append("dream_completed_date", dateCompleted)

      await axios.post('/api/v1/complete_dream_action.json',formData, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
          completeDreamProp(res.data.data)
          closeModal()
        })
    } else {
      setStatus("It's mandatory to have an after photo");
    }
  }

  async function submit(){
    setLoading(true);

    if(add_dream){
      await addDream();
    } else if(complete_dream){
      await completeDream();
    } else if(edit_dream) { //edit dream modal
      await editDream();
    }

    setLoading(false)
  }

  function deleteDreamTry(){
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteDream()
        },
        {
          label: 'No'
        }
      ]
    });
  }

  async function deleteDream(){
    setLoading(true);
    await axios.delete('/api/v1/dreams/' + data.id + '.json')
      .then(_ => {
        removeDreamProp(data.id);
      })
    setLoading(false)
  }

  async function convertHeicToJpg(file_to_update){
    let file_converted = await heic2any({
      blob: file_to_update,
      toType: "image/jpeg",
      quality: 1
    })

    file_converted.name = file_to_update.name.replace('heic','jpeg');
    let file = new File([file_converted], file_converted.name, { type: file_converted.type })

    Object.assign(file, { preview: URL.createObjectURL(file) })

    return file;
  }

  return (
    <div>
      <button className={ button_classes } onClick={() => openModal()}>
        {
          edit_dream && svgs.edit
        }
        { complete_dream && svgs.complete_dream }
        { button_title }
      </button>
      {
        modalIsOpen && 
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            htmlOpenClassName="handle-dream"
            overlayClassName="handle-dream-overlay"
          >
            <h3>{ title }</h3>
            { !complete_dream && <input type="text" name="dream_title" className="dream-title" value={dream_title} onChange={event => { setDreamTitle(event.target.value); setStatus(''); }}/> }
            { status ? <p className="status">{ status }</p> : null }
            {
              complete_dream || edit_dream ?
                <div className="date-completed">
                  <button className="button transparent" onClick={() => setCalendarStatus(!calendarStatus) }>Complete Date: { moment(dateCompleted).format('MMMM YYYY') }</button>
                  {
                    calendarStatus &&   <Calendar
                                          onChange={(date) => { setDateCompleted(date); setCalendarStatus(false); }}
                                          value={dateCompleted}
                                        />
                  }
                </div>
              : null
            }

            <br/>
            <div className="content">
              {
                (edit_dream || add_dream) ? 
                  <div className="before-column">
                    <div className="title">Before</div>
                    <br/>
                    {
                      dzActiveBefore ? 
                        <div {...getRootProps()} className="dropzone">
                          <input {...getInputProps()} />
                          {
                            isDragActive ?
                              <p className="dz-message">Drop the files here</p> :
                              <p className="dz-message">Drag your dream picture here to upload it</p>
                          }
                        </div>
                      : 
                        <div className="image-preview">
                          <button className="close-preview" onClick={() => { setDzActiveBefore(true); setFile(null); }}>x</button>
                          <img src={file ? file.preview : data.media_before_url} />
                        </div> 
                    }
                    <br/>
                    {
                      !edit_dream && <button className="button upload-btn" onClick={() => submit()} disabled={ loading } style={ loading ? { opacity: 0.7 } : null }>{ button_title }</button>
                    }
                  </div>
                : null
              }

              {
                (edit_dream || complete_dream) ? 
                  <div className="after-column">
                    <div className="title">After</div>
                    <br/>
                    {
                      dzActiveAfter ? 
                        <div {...dropzoneAfter.getRootProps()} className="dropzone">
                          <input {...dropzoneAfter.getInputProps()} />
                          {
                            isDragActive ?
                              <p className="dz-message">Drop the files here</p> :
                              <p className="dz-message">Drag your dream picture here to upload it</p>
                          }
                        </div>
                      : 
                        <div className="image-preview">
                          <button className="close-preview" onClick={() => { setDzActiveAfter(true); setFileAfter(null); setIsVideoThumbnail(false); }}>x</button>
                          {
                            isVideoThumbnail || (data.media_after_type && data.media_after_type.indexOf('video') > -1) ? 
                              <div className={`preview-video ${ isLoadingThumbnail ? 'hide' : ''}`}>
                                <VideoThumbnail
                                  videoUrl={ fileAfter ? fileAfter.preview : data.media_after_url }
                                  thumbnailHandler={() => setIsLoadingThumbnail(false)}
                                />
                                {
                                  !isLoadingThumbnail ? 
                                    <button className="thumbnail-play">
                                      { svgs.play_video }
                                    </button>
                                  : null
                                }
                              </div>
                            :
                              <img src={fileAfter ? fileAfter.preview : (data.media_after_type.indexOf('image') > -1 ? data.media_after_url : ThumbnailDefault)} />
                          }
                          { 
                            isLoadingThumbnail ? 
                              <div className="loading">
                                <ThreeDotsWave color={"black"}/> 
                              </div>
                            : null
                          }
                        </div> 
                    }
                    <br/>
                    {
                      !edit_dream ?
                        <button className="button upload-btn" onClick={() => submit()} disabled={ loading || isLoadingThumbnail } style={ loading || isLoadingThumbnail ? { opacity: 0.7 } : null }>
                          { loading ? <ThreeDotsWave/> : button_title}
                        </button>
                      : null
                    }
                  </div>
                : null
              }


            </div>
            {
              edit_dream ? 
                <div className="update-dream">
                  <button className="button upload-btn" onClick={() => submit()} disabled={ loading || isLoadingThumbnail } style={ loading || isLoadingThumbnail ? { opacity: 0.7 } : null }>
                    { loading ? <ThreeDotsWave/> : 'Update Dream' }
                  </button>
                  { user && user.role === 0 ? <button className="button delete-btn" onClick={() => deleteDreamTry()} disabled={ loading || isLoadingThumbnail } style={ loading || isLoadingThumbnail ? { opacity: 0.7 } : null }>Delete Dream</button> : null}
                </div>
              : null
            }
            
          </Modal>
      }
    </div>
  );
})

export default HandleDream