import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function SelectReact({ theme_color, options, multiselect, placeholder, addNewValues, pushFilterProp, removeFilterProp, selectedFiltersProp }) {

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      outline: 'none',
      width: '100%'
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: 45,
      boxShadow: 'none',
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderRadius: 0,
      borderColor: (theme_color === 'theme-dark' ? 'white' : '#222'),
      '&:focus': {
        borderColor: (theme_color === 'theme-dark' ? 'white' : '#222'),
      },
      '&:hover': {
        borderColor: (theme_color === 'theme-dark' ? 'white' : '#222'),
      },
      backgroundColor: (theme_color === 'theme-dark' ? '#162030' : 'white')
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 0,
    }),
    input: (provided, state) => ({
      ...provided,
      fontWeight: 600,
      color: (theme_color === 'theme-dark' ? 'white' : 'black'),
      marginBottom: 0
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 600,
      color: (theme_color === 'theme-dark' ? 'white' : 'black')
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: -2
    }),
    menuList: (provided, state) => ({
      ...provided,
      height: 100
    })
  }

  const inputEl = useRef(null);
  const [input, setInput] = useState('');
  const [optionsHandle, setOptionsHandle] = useState()
  const [optionsHandleClone, setOptionsHandleClone] = useState([])
  const [selected, setSelected] = useState(selectedFiltersProp && selectedFiltersProp[placeholder.toLowerCase()].length ? selectedFiltersProp[placeholder.toLowerCase()] : []);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    setOptionsHandle(options)
  }, [options])

  const optionsHandleRef = useRef();
  optionsHandleRef.current = optionsHandle

  const optionsHandleCloneRef = useRef();
  optionsHandleCloneRef.current = optionsHandleClone

  const selectedRef = useRef();
  selectedRef.current = selected;

  const inputRef = useRef();
  inputRef.current = input;

  const enterEvent = (key) => {
    if(key.code === 'Enter'){
      let newArr = [...optionsHandleRef.current]
      if(!newArr.find(na => na.label.toLowerCase() === inputRef.current.toLowerCase()) && addNewValues && inputRef.current.length){
        let new_select = { value: inputRef.current, label: inputRef.current };
        let newArrSelected = [...selectedRef.current];

        if(multiselect || (!multiselect && newArrSelected.length === 0)){
          newArr.push(new_select);
          setOptionsHandle(newArr);

          newArrSelected.push(new_select);

          pushFilterProp(new_select.value, placeholder.toString());
          setSelected(newArrSelected);
          setInput('');
        }
      }
    }
  }

  function keepSingleValue(value, action){
    let newArrSelected = [...selectedRef.current];
  
    if(action.action === 'remove-value'){ //we remove each option when click x button
      newArrSelected = newArrSelected.filter(nas => nas.value !== action.removedValue.value);

      removeFilterProp(action.removedValue.value, placeholder);
      setSelected(newArrSelected);

      if(!multiselect && optionsHandleCloneRef.current.length){
        setOptionsHandle(optionsHandleCloneRef.current)
      }
    } else if(action.action === "clear"){ //we remove all options 
      setSelected([]);
      removeFilterProp('all_values', placeholder)
    } else if(value.length && action.option.label && !multiselect && newArrSelected.length === 1){ //keep 1 option selected. If try to add second option we delete it
      let newArrOptions = [...optionsHandleRef.current]

      newArrOptions = newArrOptions.filter(nao => nao.label !== action.option.label);

      setOptionsHandle(newArrOptions);
    } else if(value.length && action.option.label && !newArrSelected.find(na => na.label === value.label)){ //we add option only if is not already addded
      newArrSelected.push(action.option);
      pushFilterProp(action.option.value, placeholder);
      setSelected(newArrSelected);

      if(!multiselect){
        setOptionsHandle([])
        setOptionsHandleClone(optionsHandleRef.current)
      }
    }
  }

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">{ !multiselect && selected.length == 1 ? 'You can only select 1 item' : 'No options' }</span> 
      </components.NoOptionsMessage>
    );
  };

  return (
    <Select
      ref={inputEl}
      placeholder={ placeholder === 'Sources' ? 'Title' : placeholder }
      styles={customStyles}
      closeMenuOnSelect={false}
      components={{ ...animatedComponents, DropdownIndicator:() => null, IndicatorSeparator:() => null, NoOptionsMessage: NoOptionsMessage }}
      value={selected}
      inputValue={ input }
      onChange={(value, action) => keepSingleValue(value, action)}
      isMulti={ true }
      onInputChange={(value) => setInput(value)}
      onKeyDown={(value) => enterEvent(value)}
      options={optionsHandle}
    />
  );
}