import React, { useCallback, useEffect, useState, useRef } from "react"
import Modal from 'react-modal';
import ThreeDotsWave from "./../components/ThreeDotsLoading";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 3
  }
};

Modal.setAppElement(document.createElement('div'));

const GenerateImgQuote = React.memo(({ modalStatus, handleStatus, categoryName, submitEditCategoryProp }) => {
  customStyles.content = {...customStyles.content, width: (window.innerWidth < 768 ? '90%' : '700px')}
  
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(categoryName);
  const [status, setStatus] = useState('');

  const categoryRef = useRef();
  categoryRef.current = category;

  useEffect(() => {
    setCategory(categoryName)
  },[categoryName])
  
  useEffect(() => {
    setStatus('');
  },[modalStatus])

  const submitUpdateCategory = async () => {
    if(!categoryRef.current.length) return setStatus(`The category can't be empty!`);
    setLoading(true);
    await submitEditCategoryProp(categoryRef.current)
    setStatus('The category was updated successfully');
    setLoading(false)
  }

  return (
    <div>
      <Modal
        isOpen={modalStatus}
        onRequestClose={handleStatus}
        style={customStyles}
        contentLabel="Edit Category"
        htmlOpenClassName="editCategory"
      >
        <h3>Edit Category</h3>
        { status ? <p className="status">{ status }</p> : null }
        <input type="text" name="category" value={category} onChange={event => setCategory(event.target.value)}/>
        <div className="bottom-buttons">
          <button className="button" onClick={() => submitUpdateCategory()} disabled={ loading } style={ loading ? { opacity: 0.7 } : null }>
            { loading ? <ThreeDotsWave/> : 'Update' }
          </button>
          <button className="button" onClick={handleStatus} disabled={ loading } style={ loading ? { opacity: 0.7 } : null }>Cancel</button>
        </div>
      </Modal>
    </div>
  );
})

export default GenerateImgQuote