import React, { useState } from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { confirmAlert } from 'react-confirm-alert'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

const animatedComponents = makeAnimated();

const { Option } = components;

const IconOption = props => {
  const deleteModal = () => {
    confirmAlert({
      title: `Are you sure?`,
      message: 'All links to this category will be automatically deleted!',
      buttons: [
        {
          label: 'Yes',
          onClick: () => props.selectProps.removeOption(props.data.value)
        },
        {
          label: 'No'
        }
      ],
      overlayClassName: "deleteCategory"   
    });
  }

  const editModal = () => {
    props.selectProps.handleCategoryName(props.data.value);
    props.selectProps.handleModalCategory();
  }

  return  <div className="handleOption">
            <Option {...props}>
              {props.data.label}
            </Option>
            {
              props.selectProps.placeholder === 'Category' ?
                <>
                  <button onClick={ editModal } style={{ marginRight: 4 }}><FontAwesomeIcon icon={faEdit} color="#162030" style={{ height: 18, width: 18 }}/></button>
                  <button onClick={ deleteModal }><FontAwesomeIcon icon={faTrash} color="#162030" style={{ height: 18, width: 18 }}/></button>
                </>
              : null
            }
          </div>
}


export default function SelectReactSimple({ theme_color, options, multiselect, placeholder, pushFilterProp, removeOptionProp, handleModalCategoryProp, handleCategoryNameProp }) {
  const { innerWidth: width } = window;

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      outline: 'none',
      width: width < 768 ? 230 : '100%',
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: width < 768 ? 32 : 52,
      boxShadow: 'none',
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderRadius: 0,
      borderBottomWidth: 2,
      borderColor: (theme_color === 'theme-dark' ? 'white' : '#222'),
      '&:focus': {
        borderColor: (theme_color === 'theme-dark' ? 'white' : '#222'),
      },
      '&:hover': {
        borderColor: (theme_color === 'theme-dark' ? 'white' : '#222'),
      },
      backgroundColor: (theme_color === 'theme-dark' ? '#162030' : 'white'),
      cursor: 'pointer'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 0,
    }),
    input: (provided, state) => ({
      ...provided,
      fontWeight: 600,
      color: (theme_color === 'theme-dark' ? 'white' : 'black'),
      marginBottom: 0
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 600,
      color: (theme_color === 'theme-dark' ? 'white' : 'black'),
      fontSize: width < 768 ? 13 : 16
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: -2
    }),
    menuList: (provided, state) => ({
      ...provided,
      height: 200
    }),
    dropdownIndicator: base => ({
      ...base,
      paddingRight: 0
    })
  }

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  function keepSingleValue(value, action){
    if(action.action === 'pop-value') return;
    pushFilterProp(placeholder, value[0].name, multiselect || false, true);
    setMenuIsOpen(false)
  }

  return (
    <div className={`select-container ${ theme_color }`}>
      <Select
        placeholder={ placeholder }
        styles={ customStyles }
        closeMenuOnSelect={ false }
        components={{ ...animatedComponents, Option: IconOption, IndicatorSeparator: () => null }}
        onChange={(value, action) => keepSingleValue(value, action)}
        isMulti={ true }
        value={''}
        options={ options }
        onMenuClose={() => setMenuIsOpen(false)}
        onBlur={() => setMenuIsOpen(false)}
        onMenuOpen={() => setMenuIsOpen(true)}
        menuIsOpen={menuIsOpen}
        removeOption={removeOptionProp}
        handleModalCategory={handleModalCategoryProp}
        handleCategoryName={handleCategoryNameProp}
      />
    </div>
  );
}