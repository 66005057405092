import React, { useEffect, useState, useMemo, useCallback, useRef } from "react"
import { useLocation, Redirect } from 'react-router-dom';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { confirmAlert } from 'react-confirm-alert'; 

import Header from '../components/Header';
import Search from '../components/Search';
import Select from '../components/Select';
import HandleDream from '../modals/HandleDream';
import DreamModal from '../modals/DreamModal';
import ThreeDotsWave from "../components/ThreeDotsLoading";

import svgs from '../utils/svgs';
import Hero from '../../../assets/images/hero-background.jpg';

const csrf_token = document.getElementsByName('csrf-token')[0].content;

export default function Dreams({ theme_color, changeTheme, navigation, user }){
  const [dreamsState, setDreams] = useState([])
  const [viewType, setViewType] = useState('list');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingUncomplete, setLoadingUncomplete] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedDreamDetails, setSelectedDreamDetails] = useState(null);
  const [countDreams, setCountDreams] = useState(null);
  const [resetSearch, setResetResearch] = useState(false);

  const routeParams = useLocation().state

  const dreamsStateRef = useRef();
  dreamsStateRef.current = dreamsState;

  const filtersRef = useRef();
  filtersRef.current = filters;

  const loadingMoreRef = useRef();
  loadingMoreRef.current = loadingMore;

  const countDreamsRef = useRef();
  countDreamsRef.current = countDreams;

  const tagsValues = useMemo(() => {
    return [{ name: 'All' }, { name: 'Completed' }, { name: 'Uncompleted' }]
  },[])

  const sortByValues = useMemo(() => {
    return [{ name: 'Date (asc)' }, { name: 'Date (desc)' }, { name: 'Alphabetically (asc)' }, { name: 'Alphabetically (desc)' }]
  }, [])

  const [completedValues, setCompletedValues] = useState([])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[dreamsState])

  async function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.querySelector('body>div').offsetHeight) return;
    if(!loadingMoreRef.current) return await loadMore();
  }
  
  useEffect(() => {
    let isSubscribed = true;

    getCountDreams();

    if(!routeParams){
      getDreams(null)
      getDreamsPrefillFilters();
    }
    return () => (isSubscribed = false)
  },[])

  const loadMore = async () => {
    setLoadingMore(true);
    let new_arr = [...filters]
    let filters_url = '?offset=' + dreamsState.length;

    if(new_arr.find(na => na.title === 'Tags')){
      filters_url += `&filter_by_tag=${ new_arr.find(na => na.title === 'Tags').values[0].toLowerCase() }`
    }
    
    if(new_arr.find(na => na.title === 'Search')){
      filters_url += `&search=${ new_arr.find(na => na.title === 'Search').values[0].toLowerCase() }`
    }

    if(new_arr.find(na => na.title === 'Complete Date')){
      filters_url += `&filter_by_complete_date=${ new_arr.find(na => na.title === 'Complete Date').values[0].toLowerCase() }`
    }

    if(new_arr.find(na => na.title === 'Sort By')){
      filters_url += `&sort_by=${ new_arr.find(na => na.title === 'Sort By').values[0].toLowerCase() }`
    }

   await axios.get('/api/v1/dreams.json' + filters_url || '')
    .then(res=> {
      var more_dreams = [];

      res.data.data.forEach(dream => {
        if(!dreamsState.find(current => current.id === dream.id)){
          more_dreams.push(dream);
        }
      })

      if(!dreamsState.length){
        setDreams(more_dreams);
      } else {
        setDreams(currentDreams => [...currentDreams,...res.data.data])
      }
    })

    setLoadingMore(false)
  }

  const removeDream = useCallback((id) => {
    let newArr = [...dreamsStateRef.current]
    
    if(newArr.find(d => parseInt(d.id) === id).attributes.status){
      setCountDreams({...countDreamsRef.current, completed: countDreamsRef.current.completed - 1 })
    }

    newArr = newArr.filter(d => parseInt(d.id) !== id)
    setDreams(newArr)
    setCountDreams({...countDreamsRef.current, all: countDreamsRef.current.all - 1})
  }, []);

  const ediDream = useCallback((dream) => {
    let newArr = [...dreamsStateRef.current]
    let indexDream = newArr.findIndex(d => parseInt(d.id) == dream.id)

    if(!newArr[indexDream].attributes.status && dream.attributes.status){
      setCountDreams({...countDreamsRef.current, completed: countDreamsRef.current.completed + 1})
    }

    newArr[indexDream] = dream;
    setDreams(newArr)
    getDreamsPrefillFilters();
  }, [])

  const editDreamDescription = (dream) => {
    setDreams(
      dreamsState.map(item => 
          parseInt(item.id) == dream.id 
          ? {...item, attributes : { ...item.attributes, description: dream.attributes.description}} 
          : item 
    ))
  }

  const addDream = useCallback((dream) => {
    let newArr = [...dreamsStateRef.current]

    let index_first_uncompleted = newArr.findIndex(dream => dream.attributes.status === 0);

    if(index_first_uncompleted > -1){
      newArr.splice(index_first_uncompleted, 0, dream.data.data);
      setDreams(newArr);
    }
    // newArr.unshift(dream.data.data)
    // setDreams(newArr);
    setCountDreams({...countDreamsRef.current, all: countDreamsRef.current.all + 1})
  },[])

  const completeDream = useCallback((dream) => {
    let newArr = [...dreamsStateRef.current]
    let indexDream = newArr.findIndex(d => parseInt(d.id) == dream.id)

    newArr[indexDream] = dream;

    setDreams(newArr)
    getDreamsPrefillFilters();
    setCountDreams({...countDreamsRef.current, completed: countDreamsRef.current.completed + 1})
  }, [])

  const uncompleteDream = async (dream) => {
    setLoadingUncomplete(true);
    await axios.post('/api/v1/uncomplete_dream_action.json',{
      dream_id: dream.id
    }, {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        setDreams(
          dreamsState.map(item => 
              parseInt(item.id) == dream.id 
              ? {...item, attributes : { ...item.attributes, status: 0, media_after_url: null, media_after_type: null }} 
              : item 
        ))
        setCountDreams({...countDreamsRef.current, completed: countDreamsRef.current.completed - 1})
      })

    setLoadingUncomplete(false);
    getDreamsPrefillFilters();
  }

  const uncompleteDreamTry = (dream) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => uncompleteDream(dream)
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const pushFilter = useCallback((title, value, multiselect, submit) => {
    let new_arr = [...filtersRef.current];

    let indexFilter = new_arr.findIndex(na => na.title === title);

    if(indexFilter < 0){
      if(value){
        new_arr.push({ title: title, values: [value]})
      }
    } else {
      let indexValue = new_arr[indexFilter].values.findIndex(v => v === value);

      if(indexValue < 0){
        if(!multiselect){
          if(value){
            new_arr[indexFilter].values = [value]
          } else {
            new_arr = new_arr.filter((_,index) => index !== indexFilter);
          }
        } else {
          new_arr[indexFilter].values.push(value)
        }
      }
    }

    let filters_url = '?';

    if(new_arr.find(na => na.title === 'Tags')){
      filters_url += `&filter_by_tag=${ new_arr.find(na => na.title === 'Tags').values[0].toLowerCase() }`
    }
    
    if(new_arr.find(na => na.title === 'Search')){
      filters_url += `&search=${ new_arr.find(na => na.title === 'Search').values[0].toLowerCase() }`
    }

    if(new_arr.find(na => na.title === 'Complete Date')){
      filters_url += `&filter_by_complete_date=${ new_arr.find(na => na.title === 'Complete Date').values[0].toLowerCase() }`
    }

    if(new_arr.find(na => na.title === 'Sort By')){
      filters_url += `&sort_by=${ new_arr.find(na => na.title === 'Sort By').values[0].toLowerCase() }`
    }

    if(submit){
      getDreams(filters_url);
    }
    setFilters(new_arr)
  },[])

  const clearFilters = () => {
    setResetResearch(true);
    setFilters([])
    setTimeout(() => {
      setResetResearch(false)
    }, 400)
    getDreams(null)
  }
  
  const handleOnDragEnd = (result) => {
    if(result.source && result.source.index && !result.destination) return;
    var items = Array.from(dreamsState);

    let current = result.source ? result.source.index : result.oldIndex;
    let target = result.destination ? result.destination.index : result.newIndex;

    let current_position = items[current].attributes.position;
    let target_position = items[target].attributes.position;
    let direction = target > current ? 'down' : 'up';
    let diff = current > target ? current - target : target - current;
    let items_moved = [];

    //we only replace position 
    if(diff === 1){
      items[current].attributes.position = target_position
      items[target].attributes.position = current_position

      items_moved.push({ id: items[current].attributes.id, position: target_position }, { id: items[target].attributes.id, position: current_position })
    } else {
      if(direction === 'down'){ // if we move down we we'll increase with 1 the next dreams to move dreams ups
        items.map((item) => {
          if(item.attributes.position < current_position && item.attributes.position >= target_position){
            item.attributes.position = item.attributes.position + 1;
            items_moved.push({ id: item.attributes.id, position: item.attributes.position })

            return item;
          }

          return item;
        })
      } else { // if we move down we we'll deacrease with 1 the next dreams to move dreams down
        items.map((item) => {
          if(item.attributes.position > current_position && item.attributes.position <= target_position){
            item.attributes.position = item.attributes.position - 1;

            items_moved.push({ id: item.attributes.id, position: item.attributes.position })
            return item;
          }

          return item;
        })
      }
      items[current].attributes.position = target_position
      items_moved.push({ id: items[current].attributes.id, position: target_position })
    }

    axios.put('/api/v1/dnd-dreams',{
      dreams: items_moved
    })

    const [reorderedItem] = items.splice(current, 1);
    items.splice(target, 0, reorderedItem);

    setDreams(items);
  }

  const getDreams = async (filters) => {
    setLoading(true)
    await axios.get(`/api/v1/dreams.json${filters || ''}`)
      .then(res=>{
        setDreams(res.data.data)
      })

    setLoading(false)
  }

  const getCountDreams = async () => {
    await axios.get('/api/v1/count-dreams')
      .then(res => {
        if(res.data.success){
          setCountDreams({
            all: res.data.count_all,
            completed: res.data.count_completed
          })
        }
      })
  }

  const getDreamsPrefillFilters = async () => {
    let values = []
    const { data } = await axios.get('/api/v1/dreams-completed')

    if(data && data.length){
      data.forEach(date => {
        if(date){
          values.push({ name: date.toString() })
        }
      })
    }

    setCompletedValues(values)
  }

  const SortableItem = SortableElement(({value}) => 
    <li className={`dream gallery-sortable ${ theme_color }`} key={ value.id }>
      <div className="dream-img gallery" style={{ backgroundImage: `url(${ value.attributes.media_before_url })`, width: '100%' }} onClick={() => value.attributes.status ? setSelectedDreamDetails(value) : null}>
        <button style={{ width: '100%', height: '100%'}}></button>
      </div>
      {/* <img className="dream-img" src={ value.attributes.media_before_url } onClick={() => value.attributes.status ? setSelectedDreamDetails(value) : null}/> */}

      <div className="dream-content">
        <button onClick={() => value.attributes.status ? setSelectedDreamDetails(value) : null}>
          <h5 className={ 'title ' + (value.attributes.status ? 'completed' : 'uncompleted')}>{ value.attributes.title }</h5>
        </button>

        <div className="dream-actions">
          <div className="left">
            {
              value.attributes.status ? 
                <button onClick={() => uncompleteDreamTry(value)} disabled={ loadingUncomplete } style={ loadingUncomplete ? { opacity: 0.7 } : null }>
                  {
                    loadingUncomplete ? 
                      <ThreeDotsWave/>
                    : 
                      <>
                        { svgs.complete_dream }
                        Dream completed
                      </>
                  }
                </button>
              : 'Dream uncompleted'
            }
          </div>
          <div className="right">
            <HandleDream
              title = 'Edit Dream' 
              button_title = 'Edit'
              button_classes = 'dream_complete'
              data = { value.attributes }
              edit_dream = { true }
              removeDreamProp = { removeDream }
              editDreamProp = { ediDream }
            />
            {
              !value.attributes.status &&  <HandleDream
                                  title = 'Complete Dream' 
                                  button_title = 'Complete'
                                  button_classes = 'dream_complete'
                                  data = { value.attributes }
                                  complete_dream = { true }
                                  completeDreamProp = { completeDream }
                                />
            }
          </div>
        </div>
      </div>
    </li>
  );

  const SortableList = SortableContainer(({items}) => {
    return (
      <ul className={ `dream-list ${ theme_color } gallery` }>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} disabled={ false }/>
        ))}
      </ul>
    );
  });

  if(!localStorage.getItem('token')) return <Redirect to="/login"/>

  return (
    <div className="dreams-page">
      <div className={`hero ${ theme_color }`} style={ theme_color === 'theme-dark' ? { backgroundImage: `url(${ Hero })` } : null}>
        <div className="container">
          <Header theme_color={ theme_color } changeTheme={ changeTheme } user={ user }/>

          <div className={`top-content ${ theme_color }`}>
            <h1>Dream List</h1>
            <div className="search-form">
              <Search 
                placeholder={ 'Search dreams' }
                pushFilterProp= { pushFilter }
                prefillValueProp = { routeParams && routeParams.search_param ? routeParams.search_param : null}
                reset = { resetSearch }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={`actions-container ${ theme_color }`}>

          <div className="left-column">
            {
              (completedValues.length || filters) && 
                <Select
                  title="Complete Date"
                  values={ completedValues }
                  pushFilterProp= { pushFilter }
                  theme_color={ theme_color }
                />
            }

            <Select
              title="Tags"
              values={ tagsValues }
              pushFilterProp= { pushFilter }
              theme_color={ theme_color }
            />

            <Select
              title="Sort By"
              values={ sortByValues }
              pushFilterProp= { pushFilter }
              theme_color={ theme_color }
            />
          </div>

          <div className="right-column">
            {
              viewType === 'list' ?
                <button onClick={() => setViewType('gallery')} className="view-types"><span>{ svgs.gallery }</span>Gallery View</button>
              : 
                <button onClick={() => setViewType('list')} className="view-types"><span>{ svgs.list }</span>List View</button>
            }

            <HandleDream
              title = 'Add New Dream'
              button_title = 'Add New Dream'
              button_classes ={`button add_dream ${ theme_color }`}
              add_dream = { true }
              addDreamProp = { addDream }
            />
          </div>
        </div>

        {
          filters.length ?
            <div className={`actions-filters ${ theme_color }`}>
              <button className={`button transparent ${ theme_color }`} onClick={() => clearFilters()}>Clear all filters</button>
              {
                filters.map((filter,index) => (
                  <div className="filter-element" key={ index }>
                    <span className="title">{ filter.title }:</span>
                    {
                      filter.values.map((v,index) => (
                        <button className="border" key={ index }>{ v }</button>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          : null
        }

        {
          countDreams ? 
            <div className={`total ${ theme_color }`}><p>{ countDreams.completed } / { countDreams.all }</p></div>
          : null
        }

        {
          loading ? 
            <div className="loading">
              <ThreeDotsWave color={ theme_color === 'theme-white' ? 'black' : 'white' }/>
            </div>
          :
            viewType === 'list' ?
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                      <ul className={ `dream-list ${ theme_color }` + (viewType === 'list' ? ' list' : ' gallery')} {...provided.droppableProps} ref={ provided.innerRef }>
                        {
                          dreamsState.length ?
                            dreamsState.map((dream, index) => {
                              return (
                                <Draggable key={ dream.id } draggableId={ dream.id } index={ index } isDragDisabled={ viewType === 'list' ? false : false }>
                                  {(provided) => (
                                      <li className="dream" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                        {/* <img className="dream-img" src={ dream.attributes.media_before_url } onClick={() => dream.attributes.status ? setSelectedDreamDetails(dream) : null}/> */}
                                        <div className="dream-img gallery" style={{ backgroundImage: `url(${ dream.attributes.media_before_url })`, width: '100%' }} onClick={() => dream.attributes.status ? setSelectedDreamDetails(dream) : null}>
                                        </div>

                                        <div className="dream-content">
                                          <h5 className={ 'title ' + (dream.attributes.status ? 'completed' : 'uncompleted')} onClick={() => dream.attributes.status ? setSelectedDreamDetails(dream) : null }>{ dream.attributes.title }</h5>

                                          <div className="dream-actions">
                                            <div className="left">
                                              {
                                                dream.attributes.status ? 
                                                  <button onClick={() => uncompleteDreamTry(dream)} disabled={ loadingUncomplete } style={ loadingUncomplete ? { opacity: 0.7 } : null }>
                                                    {
                                                      loadingUncomplete ? 
                                                        <ThreeDotsWave/>
                                                      : 
                                                        <>
                                                          { svgs.complete_dream }
                                                          Dream completed
                                                        </>
                                                    }
                                                  </button>
                                                : 'Dream uncompleted'
                                              }
                                            </div>
                                            <div className="right">
                                              <HandleDream
                                                title = 'Edit Dream' 
                                                button_title = 'Edit'
                                                button_classes = 'dream_complete'
                                                data = { dream.attributes }
                                                edit_dream = { true }
                                                removeDreamProp = { removeDream }
                                                editDreamProp = { ediDream }
                                                user = { user }
                                              />
                                              {
                                                !dream.attributes.status &&  <HandleDream
                                                                    title = 'Complete Dream' 
                                                                    button_title = 'Complete'
                                                                    button_classes = 'dream_complete'
                                                                    data = { dream.attributes }
                                                                    complete_dream = { true }
                                                                    completeDreamProp = { completeDream }
                                                                  />
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  }
                                </Draggable>
                              )
                            })
                          : <center>Unfortunately no dream were found!</center>
                        } 
                        { provided.placeholder }
                      </ul>
                    )}
                </Droppable>
              </DragDropContext>
            : 
              <SortableList items={ dreamsState } onSortEnd={handleOnDragEnd} axis={"xy"} pressDelay={200} shouldCancelStart={() => null }/>
        }

        {
          loadingMore && !loading ? 
            <div className="loading absolute">
              <ThreeDotsWave/>
            </div>
          : null
        }

        { selectedDreamDetails &&  <DreamModal selectedDreamDetails={ selectedDreamDetails } setSelectedDreamDetails={setSelectedDreamDetails} editDreamProp = { editDreamDescription }/> }
      </div>
    </div>
  );
}
