import React, { useCallback, useEffect, useState } from "react"
import Modal from 'react-modal';
import svgs from '../utils/svgs';
import axios from "axios";
import ThreeDotsWave from "../components/ThreeDotsLoading";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 3
  }
};

Modal.setAppElement(document.createElement('div'));
// const csrf_token = document.getElementsByName('csrf-token')[0].content;
// axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token;

const SearchLibrary = React.memo(({ theme_color, button_title, button_classes, data, books_status, addLibraryProp }) => {
  const { innerWidth: width, innerHeight: height } = window;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchOutput, setSearchOutput] = useState([]);
  const [status, setStatus] = useState('');
  const [loadingAddLibrary, setLoadingAddLibrary] = useState(null);
  const [imdb, setImdb] = useState(false);

  customStyles.content = {...customStyles.content, width: (window.innerWidth < 768 ? '90%' : '700px')}

  function openModal() {
    setIsOpen(true);
    setSearchOutput([])
    setImdb(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const submitSearch = async () => {
    setLoading(true);
    setSearchOutput([]);
    setStatus('')
    var url = `api/v1/${ books_status ? 'search-books' : 'search-documentaries' }`;

    try{
      const { data } = await axios.post(url,{
        search: search,
        maxResults: width < 768 ? 6 : 10,
        imdb: imdb
      })

      var output = [];
  
      if(books_status){
        if(data && data.items && data.items.length) output = data.items.filter(item => item.volumeInfo.title);
      } else {
        if(imdb){
          if(data && data.results && data.results.length) output = data.results
        } else {
          if(data && data.resultCount) output = data.results
        }
      }

      if(output.length) setSearchOutput(output)
      else setStatus("Your search didn't return any results")
    } catch(error){
    }

    setLoading(false);
  }

  const submitAddLibrary = async (item_selected) => {
    setLoadingAddLibrary(item_selected);

    if(books_status){
      await submitAddBook(item_selected)
    } else {
      await submitAddDocumentary(item_selected);
    }

    setLoadingAddLibrary(null);
  }

  const submitAddBook = async (item_selected) => {
    let item_chosen = searchOutput.find(item => item.id == item_selected)
    console.log(item_chosen);

    var bodyData = {
      library_volume_id: item_selected,
      library_title: item_chosen.volumeInfo.title,
      library_description: item_chosen.volumeInfo.description,
      library_link_url: item_chosen.volumeInfo.previewLink || item_chosen.volumeInfo.infoLink,
      library_type: 0
    }

    if(item_chosen.volumeInfo.authors && item_chosen.volumeInfo.authors.length){
      bodyData.library_authors = item_chosen.volumeInfo.authors
    }

    if(item_chosen.volumeInfo.categories && item_chosen.volumeInfo.categories.length){
      bodyData.library_categories = item_chosen.volumeInfo.categories
    }

    await axios.post('/api/v1/libraries', bodyData)
      .then(res => {
        if(res.data.error){ 
          setStatus(res.data.error)
        } else {
          closeModal();
          addLibraryProp(res)
        }
      })

    return true;
  }

  const submitAddDocumentary = async (item_selected) => {
    let media_url;
    let item_chosen = searchOutput.find((_,index) => index == item_selected)
    console.log(item_chosen);

    var bodyData = {
      library_title: item_chosen.collectionName || item_chosen.trackName || item_chosen.title || '',
      library_description: item_chosen.longDescription || item_chosen.description || item_chosen.shortDescription || '',
      library_link_url: item_chosen.collectionViewUrl || item_chosen.trackViewUrl || item_chosen.previewUrl || '',
      library_type: 1,
      library_id: imdb ? item_chosen.id : null,
      imdb: imdb
    }

    if(item_chosen.artistName){
      bodyData.library_authors = [item_chosen.artistName]
    }

    if(item_chosen.primaryGenreName){
      bodyData.library_categories = [item_chosen.primaryGenreName]
    }

    if(item_chosen.artworkUrl100){
      media_url = item_chosen.artworkUrl100
    } else if(item_chosen.artworkUrl60){
      media_url = item_chosen.artworkUrl60
    }
    
    if(media_url){
      var rest = media_url.substring(0, media_url.lastIndexOf("/") + 1);
      var last = media_url.substring(media_url.lastIndexOf("/") + 1, media_url.length);
  
      var new_last_url = '600x600bb' + last.substring(last.lastIndexOf("b") + 1, last.length);
      var new_last_url_optimized = '200x200bb' + last.substring(last.lastIndexOf("b") + 1, last.length);

      bodyData.library_media = {
        url: rest + new_last_url,
        url_optimized: rest + new_last_url_optimized
      }
    } else if(item_chosen.image){
      bodyData.library_media = {
        url: item_chosen.image,
        url_optimized: item_chosen.image
      }
    }

    await axios.post('/api/v1/libraries', bodyData)
      .then(res => {
        if(res.data.error){ 
          setStatus(res.data.error)
        } else {
          closeModal();
          addLibraryProp(res)
        }
      })

    return true;
  }

  const detectEnter = (e) => {
    if (e.charCode === 13) {
      submitSearch()
    }
  }

  return (
    <div>
      <button className={ button_classes } onClick={() => openModal()}>
        { button_title }
      </button>
      {
        modalIsOpen && 
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            htmlOpenClassName="search-library"
          >
            <h3>{ books_status ? 'SEARCH FOR BOOKS' : 'SEARCH FOR DOCUMENTARIES' }</h3>
            <div className="content">
              {
                !books_status ? 
                  <div className="doc-search-buttons">
                    <button className={`button small ${imdb === true ? 'transparent' : ''}`} onClick={() => setImdb(false)}>Search in iTunes</button>
                    <button className={`button small ${imdb === false ? 'transparent' : ''}`} onClick={() => setImdb(true)}>Search in IMDB</button>
                  </div>
                : null
              }
              <input type="text" name="share_from" value={ search } onChange={event => { setSearch(event.target.value); setStatus('') }} onKeyPress={(event) => detectEnter(event)} placeholder={books_status ? "Book Title" : "Documentary Title"}/>
              { status ? <p className="status">{ status }</p> : null }
              {
                searchOutput.length ?
                  <ul className="output-search">
                    {
                      searchOutput.map((item,index) => {
                        if(books_status){
                          return  <li key={ item.id }>
                                    <div className="content-book">
                                      <div className="book-image" style={ item.volumeInfo.imageLinks ? { backgroundImage: `url(${ item.volumeInfo.imageLinks.smallThumbnail })` } : null}></div>
                                      {
                                        item.volumeInfo.title ?
                                          <h6>
                                            { width < 768 ? item.volumeInfo.title.length > 20 ? item.volumeInfo.title.substring(0,19) + '...' : item.volumeInfo.title :
                                            item.volumeInfo.title.length > 35 ? item.volumeInfo.title.substring(0,34) + '...' : item.volumeInfo.title }
                                          </h6>
                                        : null
                                      }
                                      {
                                        item.volumeInfo.authors ? 
                                          <p className="author"><span>by</span><br/>{ item.volumeInfo.authors[0].length > 20 ? item.volumeInfo.authors[0].substring(0,19) + '...' : item.volumeInfo.authors[0] }</p>
                                        : null
                                      }
                                    </div>
                                    <button className="button" onClick={() => submitAddLibrary(item.id)} disabled={ loadingAddLibrary } style={ loadingAddLibrary ? { opacity: 0.7 } : null }>
                                      { loadingAddLibrary && loadingAddLibrary == item.id ? <ThreeDotsWave/> : 'Add Book' }
                                    </button>
                                  </li>
                        } else {
                          return  <li key={ index }>
                                    <div className="content-book">
                                      <div className="book-image" style={ item.artworkUrl60 || item.image ? { backgroundImage: `url(${ item.artworkUrl60 || item.image })` } : item.image}></div>
                                      <h6>
                                        { item.collectionName ? 
                                            item.collectionName.length > 30 ? item.collectionName.substring(0,29) + '...' : item.collectionName : 
                                              item.trackName ? item.trackName.length > 30 ? item.trackName.substring(0,29) + '...' : item.trackName : 
                                              item.title ? item.title.length > 30 ? item.title.substring(0,29) + '...' : item.title : ''
                                        }
                                      </h6>
                                      {
                                        item.artistName ? 
                                          <p className="author"><span>by</span><br/>{ item.artistName ? item.artistName.substring(0,19) + '...' : item.artistName }</p>
                                        : null
                                      }
                                    </div>
                                    <button className="button" onClick={() => submitAddLibrary(index)} disabled={ loadingAddLibrary } style={ loadingAddLibrary ? { opacity: 0.7 } : null }>
                                      { loadingAddLibrary && loadingAddLibrary == item.id ? <ThreeDotsWave/> : 'Add Documentary' }
                                    </button>
                                  </li>
                        }
                      })
                    }
                  </ul>
                : null
              }
              <button className="button upload-btn" onClick={() => submitSearch()} disabled={ loading || loadingAddLibrary } style={ loading || loadingAddLibrary ? { opacity: 0.7 } : null }>
                {
                  loading ? <ThreeDotsWave/> : 'Search'
                }
              </button>
            </div>
          </Modal>
      }
    </div>
  );
})

export default SearchLibrary